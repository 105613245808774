import {searchAbout} from './searchAbout'
import {searchContactUs} from './searchContactUs'
import {searchHome} from './searchHome'
import {searchProductAnalyse} from './searchProductAnalyse'
import {searchProductBIM} from './searchProductBIM'
import {searchProductDiary} from './searchProductDiary'
import {searchProductEDMS} from './searchProductEDMS'
import {searchProductInspection} from './searchProductInspection'
import {searchProductKnowledgement} from './searchProductKnowledgement'
import {searchProductPhotoReg} from './searchProductPhotoReg'
import {searchProductProcurement} from './searchProductProcurement'
import {searchProductSignature} from './searchProductSignature'


export const searchIndex = [
    searchAbout,
    searchContactUs,
    searchHome,
    searchProductAnalyse,
    searchProductBIM,
    searchProductDiary,
    searchProductEDMS,
    searchProductInspection,
    searchProductKnowledgement,
    searchProductPhotoReg,
    searchProductProcurement,
    searchProductSignature
]