import './App.css';
import React, { useState,useEffect } from 'react';
import { Button, Grid ,Table,TableBody,TableCell,TableHead,TableRow,Paper,Hidden, Container} from '@material-ui/core';
import { useParams} from 'react-router-dom'
import {product_JSON} from './product_json'
import { NavBar } from './navbar'
import { Footer } from './footer'
import { ModalScreen } from './modal'
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
// import anime from 'animejs/lib/anime.es.js';
import { useWindowSize } from './windowSize'
import ReactPlayer from 'react-player'
import {
    isMobile,
  } from "react-device-detect";

function OverView ({data}){
    if (data.image === '' ){
        return(
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                        <label className="font-nunito-regular color-black-medium letter-spacing-s font-size-rem-xs" >{data.overview}</label>
                    </ScrollAnimation>
            
            )
    }else{
        return(
            
            <Grid container alignItems="center" style={{width:'100%'}} >
                <Grid item  xs={12} sm={6}>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                        <img src={data.image} style={{width:'100%'}} alt=""/>
                    </ScrollAnimation>
                </Grid>
                <Grid item  xs={12} sm={6}>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                        <label className="font-nunito-regular color-black-medium letter-spacing-s font-size-rem-xs margin-header-content-top" style={{padding:isMobile?'0':'0 3rem'}}>{data.overview}</label>
                    </ScrollAnimation>
                </Grid>
            </Grid>
                
            )
    }
    
}
const CssButton = withStyles({
    root:{
        '&:hover':{
            backgroundColor:"transparent"
        },
        
    },
    label:{
        height:'100%',
        // display:'unset',
    }
})(Button)

function Details ({data}){
    const [width] = useWindowSize();

    const StyledTableCell = withStyles((theme) => ({
        head: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          fontFamily:'Montserrat',
          fontWeight:700,
        },
        body: {
          fontSize: 14,
          width:'fit-content',
          fontFamily:'Montserrat',
          fontWeight:700,
        },
        root:{
            borderBottom: "none",
            padding:'16px 0px 16px 16px'
        }
      }))(TableCell);
      
      const StyledTableRow = withStyles((theme) => ({
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }))(TableRow);
    const tableHeader = ['Function','Management','MIC','PIC','Admin']
    function createData(input) {
        return { name:input[0], Management:input[1], MIC:input[2], PIC:input[3], Admin:input[4] };
    }
    if (data.details.length !== 0 ){
            const detailsContent = data.details.map((detail,index)=>{
                if(index%2 ===0){
                    return(
                        <div>
                            <Hidden smUp>
                                <div>
                                    <Grid  container key={index}  alignItems="center" justify="center" >
                                        <Grid item >
                                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                <img src={detail.image} className="w-100 margin-header-content-top" style={{}} alt=""/>
                                            </ScrollAnimation>
                                        </Grid>
                                        <Grid item >
                                            <div className="margin-header-content ">
                                                <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                    <label className="font-montserrat-bold letter-spacing-s color-black font-size-px-sm">{detail.title}</label>
                                                    <label className="font-montserrat-bold color-purple font-size-rem-sm" style={{marginLeft:'5px'}}> .</label>
                                                </ScrollAnimation>
                                            </div>
                                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                <label className="font-nunito-regular color-lightblack letter-spacing-s font-size-px-sm " style={{}}>{detail.des}</label>
                                            </ScrollAnimation>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Hidden>
                            <Hidden xsDown>
                                <div className="margin-header-content-top">
                                    <Grid  container key={index}  alignItems="center" justify="center" >
                                        <Grid  item  xs={6} >
                                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                <img className="w-100" src={detail.image} style={{padding:'0 1rem'}} alt=""/>
                                            </ScrollAnimation>
                                        </Grid>
                                        <Grid item xs={6} style={{padding:'0 3rem'}}>
                                            <div className="margin-header-content" >
                                                <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                    <label className="font-montserrat-bold letter-spacing-s color-black font-size-rem-xs">{detail.title}</label>
                                                    <label className="font-montserrat-bold color-purple font-size-rem-sm" style={{marginLeft:'5px'}}> .</label>
                                                </ScrollAnimation>
                                            </div>
                                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                <label className="font-nunito-regular color-lightblack letter-spacing-s font-size-rem-xs margin-header-content" style={{}}>{detail.des}</label>
                                            </ScrollAnimation>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Hidden>
                        </div>
                       
                    )
                
                }
                else{
                    return(
                        <div>
                            <Hidden smUp>
                                <div >
                                    <Grid  container key={index}  alignItems="center" justify="center" >
                                        <Grid item >
                                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                <img src={detail.image} className="w-100 margin-header-content-top" style={{}} alt=""/>
                                            </ScrollAnimation>
                                        </Grid>
                                        <Grid item >
                                            <div className="margin-header-content">
                                                <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                    <label className="font-montserrat-bold letter-spacing-s color-black font-size-px-sm">{detail.title}</label>
                                                    <label className="font-montserrat-bold color-purple font-size-rem-sm" style={{marginLeft:'5px'}}> .</label>
                                                </ScrollAnimation>
                                            </div>
                                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                <label className="font-nunito-regular color-lightblack letter-spacing-s font-size-px-sm " style={{}}>{detail.des}</label>
                                            </ScrollAnimation>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Hidden>
                            <Hidden xsDown>
                                <div className="margin-header-content-top">
                                    <Grid  container key={index}  alignItems="center" justify="center" >
                                        <Grid item xs={6} style={{padding:'0 3rem'}}>
                                            <div className="margin-header-content" >
                                                <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                    <label className="font-montserrat-bold letter-spacing-s color-black font-size-rem-xs">{detail.title}</label>
                                                    <label className="font-montserrat-bold color-purple font-size-rem-sm" style={{marginLeft:'5px'}}> .</label>
                                                </ScrollAnimation>
                                            </div>
                                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                <label className="font-nunito-regular color-lightblack letter-spacing-s font-size-rem-xs margin-header-content" style={{}}>{detail.des}</label>
                                            </ScrollAnimation>
                                        </Grid>
                                        <Grid  item  xs={6} >
                                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                                <img className="w-100" src={detail.image} style={{padding:'0 1rem'}} alt=""/>
                                            </ScrollAnimation>
                                        </Grid>
                                       
                                    </Grid>
                                </div>
                            </Hidden>
                        </div>
                       
                    )
                }
            })
            return(
                <div style={{}}>
                    <div>
                        <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                            <label className="font-montserrat-bold color-purple font-size-rem-xl margin-dot-top">.</label>
                        </ScrollAnimation>
                    </div>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                    <label className="font-montserrat-bold color-black letter-spacing-m font-size-header ">Product Details</label>
                    </ScrollAnimation>
                    {detailsContent}
                </div>
                )

    }else{
        if (data.table.length>0){
            const tableData = data.table.map(item=>createData(item))
            // console.log(tableData)
            return(
                <div style={{width:isMobile?width*0.9:'none'}}>
                    <Grid container direction="column" style={{marginTop:'50px',width:'fit-content'}}>
                        <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                            <label className="font-montserrat-bold color-purple font-size-rem-xl">.</label>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                            <label className="font-montserrat-bold color-black letter-spacing-m font-size-header margin-header-content">Product Details</label>
                        </ScrollAnimation>
                        <div className="margin-header-content">
                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                <label className="font-montserrat-bold color-black font-size-rem-xs" >Compare User Roles</label>
                                <label style={{marginLeft:'5px'}} className="font-montserrat-bold color-purple font-size-rem-sm"> .</label>
                            </ScrollAnimation>
                          
                        </div>
                    </Grid>
                    <Paper elevation={0} border={0} style={{overflowX:'auto'}} className="">
                    {/* <TableContainer  style={{margin:'2vh 0%',overflowX:'auto',width:'100%'}} elevation={0} border={0} component={Paper}> */}
                        <Table aria-label="simple table" style={{minWidth:isMobile?'1000px':'0px'}}>
                            <ScrollAnimation animateIn='animate__fadeInUp w-100'  animateOnce={true} >
                            <TableHead>
                                <TableRow>
                                <ScrollAnimation animateIn='animate__fadeInUp w-100'  animateOnce={true}  >
                                    {tableHeader.map((item,index)=>(
                                        <StyledTableCell className="font-montserrat-bold color-black font-size-rem-xs letter-spacing-s" style={{width:'200px'}} align={index===0?'left':'center'}>{item}</StyledTableCell>
                                    ))}
                                    </ScrollAnimation>
                                </TableRow>
                            </TableHead>
                            </ScrollAnimation>
                            <TableBody>
                            {tableData.map((row,index) => (
                                <ScrollAnimation animateIn='animate__fadeInUp w-100'  animateOnce={true}  style={{background:index%2===0?'#F6F5FF':'#FFFFFF'}} >
                                <StyledTableRow key={row.name} style={{background:index%2===0?'#F6F5FF':'#FFFFFF'}}>
                                <StyledTableCell style={{width:'200px'}} className="font-montserrat-bold color-black font-size-rem-xs letter-spacing-s" component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell style={{width:'200px'}} align="center">{row.Management?<FontAwesomeIcon color="#2CE2C3" icon={["fas", "check"]}/>:null}</StyledTableCell>
                                <StyledTableCell style={{width:'200px'}} align="center">{row.MIC?<FontAwesomeIcon color="#2CE2C3" icon={["fas", "check"]}/>:null}</StyledTableCell>
                                <StyledTableCell style={{width:'200px'}} align="center">{row.PIC?<FontAwesomeIcon color="#2CE2C3" icon={["fas", "check"]}/>:null}</StyledTableCell>
                                <StyledTableCell style={{width:'200px'}} align="center">{row.Admin?<FontAwesomeIcon color="#2CE2C3" icon={["fas", "check"]}/>:null}</StyledTableCell>
                                </StyledTableRow>
                                </ScrollAnimation>
                            ))}
                            </TableBody>
                        </Table>
                    {/* </TableContainer> */}
                    </Paper>

                </div>
            )
        }else{

        
        return(
            <div >
                
            </div>
            )
        }
    }
}


function Projects ({data}) {
    const [height] = useWindowSize();
    if (data !=='' ){
        return(
            <div className="" style={{background: 'linear-gradient(to bottom, white 50%, #12043F 50%)'}}>
                <Container maxWidth="lg" >
                    <Grid container direction="column" className="" style={{padding:"0 4vw"}}>
                        <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                            <Grid item xs={12} >
                                <div>
                                    <label className="font-montserrat-bold color-blue font-size-rem-xl ">.</label>
                                </div>
                                <label className="font-montserrat-bold color-black letter-spacing-m font-size-header margin-header-content" style={{}}>Projects Portfolio</label>
                            </Grid>
                        </ScrollAnimation>
                        <Grid container
                            direction="column" justify="center">
                            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                                <Grid 
                                    item  xs={12} >
                                    <label className="font-montserrat-bold color-black letter-spacing-m font-size-subHeader" style={{}}>Yau Ma Tei Car Park Demolition</label>
                                </Grid>
                                <CssButton style={{padding:'0px'}} onClick={()=>window.open('https://www.thb.gov.hk/eng/blog/index_id_46.htm', "_blank")}>
                                    <div className="margin-header-content" style={{display:'flex', flexDirection:'row'}}>
                                        <label className="pointer-cursor font-montserrat-bold color-blue font-size-xs " style={{}}>Learn more</label>
                                        <FontAwesomeIcon size="xs" style={{position:"relative",alignSelf:'center',left:"5%"}} className="pointer-cursor"  color="#12043F" icon={["fas", "arrow-right"]} />
                                    </div>
                                        
                                </CssButton>
                            </ScrollAnimation>
                        </Grid>
                     
                        <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                            <Grid item xs={12} >
                                <ReactPlayer
                                    url={data.video}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                    // other props      
                                />
                            
                            
                            </Grid>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                            <Grid item xs={12} >
                                <span className="font-nunito-italic color-grey card-data-font-size" style={{}}>
                                    Source from&nbsp;
                                    <label  className="font-nunito-italic color-grey classA margin-header-content" onClick={()=>window.open(data.link, "_blank")} >{data.link}</label>
                                </span>
                            </Grid>
                        </ScrollAnimation>
                       
                    </Grid>
                </Container>
            </div>
            
        )
    }else{
        return(
            <div>

            </div>
        )
    }
   
}



export function Product(){
    const [saveTop, setSaveTop ] = useState(0)
    const [width,] = useWindowSize();
   const [open, setOpen ] = useState(false)


  useEffect(()=>{
    if(open){
        document.getElementById('root').style.position = 'fixed'
        document.getElementById('root').style.top = `-${saveTop}px`

    }else{
        document.getElementById('root').style.removeProperty('position')
        document.getElementById('root').style.removeProperty('top')
        document.documentElement.scrollTop = document.body.scrollTop = saveTop

    }
},[open])

    let { name } = useParams();
    const data = product_JSON[name]

  const handleOpen = () => {
    setOpen(true)
    setSaveTop(document.body.scrollTop || document.documentElement.scrollTop || 0)
}

        return(
            <div className="" style={{background:'white'}}>
                <NavBar bgColor="rgba(18,4,63,0.58)" ratio='0.2' model={open}/>
                <div className="videoContainer" style={{height:'fit-content',overflow:'hidden',maxHeight:'500px'}}>
                    <div className="Image-Cover" style={{background:"rgba(18,4,63,0.58)",height:'100%'}}> 
                        <Hidden smUp>
                            <div style={{position:'relative', margin:'3rem',top:'22.5%'}}>
                                <Grid container  direction="column" spacing={1} style={{textAlign:'center', height:'100%'}}>
                                    <Grid item>
                                        <img  src={data.icon} style={{height:'3rem'}} alt=""/>
                                    </Grid>
                                    {data.type!==''?
                                    <Grid item>
                                        <label  className="font-montserrat-bold letter-spacing-s font-size-des color-white banner-text-shadow" style={{fontSize:'0.65rem', textTransform:'uppercase'}}>{data.type}</label>
                                    </Grid>:null}
                                    <Grid  item >
                                        <label  className="font-montserrat-bold letter-spacing-s font-size-headercolor-white banner-text-shadow" style={{fontSize:'1rem'}}>{data.name}</label>
                                    </Grid>
                                    <Grid item style={{marginTop:'10px'}}>
                                        <Button  className="btn-transition-none btn-gradient shadow-none" onClick={()=>handleOpen()} variant="contained" size='medium' fullWidth color="primary" style={{borderRadius:5,backgroundColor:'#F1A039',width:'300px'}}>
                                            <label className="font-montserrat-bold letter-spacing-s button-size-label button-padding-h w-100" style={{ textTransform:'initial'}}>{data.projects!== ''?'Contact Us':'Request a Demo'}</label>
                                        </Button>
                                    </Grid>
                                    <Grid item style={{textAlign:'right'}}>
                                        {data.desktop?<FontAwesomeIcon  style={{marginLeft:'3%'}} color="white" icon={["fas", "desktop"]}/>:null}
                                        {data.mobile?<FontAwesomeIcon  style={{marginLeft:'3%'}} color="white" icon={["fas", "mobile-alt"]} />:null}
                                    </Grid>
                                </Grid>
                            </div>
                        </Hidden>
                        <Hidden xsDown>
                        <div style={{position:'relative',top:'30%',left:'4vw'}}>
                                <Container maxWidth="lg" >
                                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                        <Grid className="start-animation" container  direction="column" spacing={1} >
                                            <Grid item  >
                                            
                                                <img id="product_icon" src={data.icon} style={{width:'5rem'}} alt=""/>
                                            </Grid>

                                            {data.type!==''?
                                            <Grid item style={{}} >
                                                <label id='product_type' className="font-montserrat-bold letter-spacing-s font-size-des color-white" style={{textTransform:'uppercase'}}>{data.type}</label>
                                            </Grid>:null}
                                            <Grid  item >
                                                <label id="product_name" className="font-montserrat-bold letter-spacing-s font-size-headercolor-white" >{data.name}</label>
                                            </Grid>
                                            <Grid item style={{marginTop:'10px'}}>
                                                <Button id="request_btn" className="btn-transition-none btn-gradient shadow-none" onClick={()=>handleOpen()} variant="contained" size='medium'  color="primary" style={{width:'250px',borderRadius:5,backgroundColor:'#F1A039',marginLeft:'1%'}}>
                                                    <label className="font-montserrat-bold letter-spacing-s button-size-label button-padding-h" style={{textTransform:'initial'}}>{data.projects!== ''?'Contact Us':'Request a Demo'}</label>
                                                </Button>
                                                {data.desktop?<FontAwesomeIcon id="product_desktop" style={{marginLeft:'3%'}} color="white" icon={["fas", "desktop"]}/>:null}
                                                {data.mobile?<FontAwesomeIcon id="mobile" style={{marginLeft:'3%'}}color="white" icon={["fas", "mobile-alt"]} />:null}
                                            </Grid>
                                        </Grid>
                                    </ScrollAnimation>
                                </Container>
                            </div>
                        </Hidden>
                    </div>
                    <Hidden lgDown>
                        <img src={data.bg} style={{width:width}} alt=""/>
                    </Hidden>
                    <Hidden xlUp>
                        <img src={data.bg} style={{height:'100%'}} alt=""/>
                    </Hidden>
                </div>
                <Container maxWidth="lg" >
                    <Grid container direction="column" className="margin-dot-top" style={{padding:"0 4vw"}}>
                        <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                        <Grid item xs={12} >
                            <div>
                                <label className="font-montserrat-bold color-orange font-size-rem-xl ">.</label>
                            </div>
                            <label className="font-montserrat-bold color-black letter-spacing-m font-size-header margin-header-content" style={{}}>Overview</label>
                        </Grid>
                        </ScrollAnimation>

                        {product_JSON.hasOwnProperty(name)?
                            <div style={{}}>
                                <Grid item xs={12} >
                                    <OverView data={data}/>
                                </Grid>
                                <Grid item xs={12} >
                                    <Details data={data}/>
                                </Grid>
                               
                            </div>
                        
                        :null}
                        <Grid item>
                            <Hidden xsDown>
                                {/* {data.projects!== ''?
                                 <Grid container className="margin-nodot-top" direction="column" alignItems="center" style={{}}>
                                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                        <Button className="btn-transition-none btn-gradient shadow-none" onClick={()=>handleOpen()} variant="contained" size='medium'  color="primary" style={{width:'250px',borderRadius:5,backgroundColor:'#6561B2',marginLeft:'1%'}}>
                                            <label className="font-montserrat-bold letter-spacing-s button-size-label button-padding-h" style={{textTransform:'initial'}}>Contact Us</label>
                                        </Button>
                                    </ScrollAnimation>
                                </Grid>
                                :
                                <Grid container className="margin-end-content margin-nodot-top" direction="column" alignItems="center" style={{}}>
                                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                        <Button className="btn-transition-none btn-gradient shadow-none" onClick={()=>handleOpen()} variant="contained" size='medium'  color="primary" style={{width:'250px',borderRadius:5,backgroundColor:'#6561B2',marginLeft:'1%'}}>
                                            <label className="font-montserrat-bold letter-spacing-s button-size-label button-padding-h" style={{textTransform:'initial'}}>Request a Demo</label>
                                        </Button>
                                    </ScrollAnimation>
                                </Grid>} */}
                                 <Grid container className="margin-end-content margin-nodot-top" direction="column" alignItems="center" style={{}}>
                                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                        <Button className="btn-transition-none btn-gradient shadow-none" onClick={()=>handleOpen()} variant="contained" size='medium'  color="primary" style={{width:'250px',borderRadius:5,backgroundColor:'#6561B2',marginLeft:'1%'}}>
                                            <label className="font-montserrat-bold letter-spacing-s button-size-label button-padding-h" style={{textTransform:'initial'}}>Request a Demo</label>
                                        </Button>
                                    </ScrollAnimation>
                                </Grid>
                            </Hidden>
                            <Hidden smUp>
                            {/* {data.projects!== ''?
                            <Grid container className="margin-nodot-top" direction="column" alignItems="center" style={{}}>
                                <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                    <Button className="btn-transition-none btn-gradient shadow-none" onClick={()=>handleOpen()} variant="contained" size='medium'  color="primary" style={{width:'300px',borderRadius:5,backgroundColor:'#6561B2',marginLeft:'1%'}}>
                                        <label className="font-montserrat-bold letter-spacing-s button-size-label button-padding-h w-100" style={{textTransform:'initial'}}>Contact Us</label>
                                    </Button>
                                </ScrollAnimation>
                            </Grid>
                            :
                            <Grid container className="margin-nodot-top" direction="column" alignItems="center" style={{}}>
                                <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                    <Button className="btn-transition-none btn-gradient shadow-none" onClick={()=>handleOpen()} variant="contained" size='medium'  color="primary" style={{width:'300px',borderRadius:5,backgroundColor:'#6561B2',marginLeft:'1%'}}>
                                        <label className="font-montserrat-bold letter-spacing-s button-size-label button-padding-h w-100" style={{textTransform:'initial'}}>Request a Demo</label>
                                    </Button>
                                </ScrollAnimation>
                            </Grid>} */}
                            <Grid container className="margin-nodot-top" direction="column" alignItems="center" style={{}}>
                                <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} >
                                    <Button className="btn-transition-none btn-gradient shadow-none" onClick={()=>handleOpen()} variant="contained" size='medium'  color="primary" style={{width:'300px',borderRadius:5,backgroundColor:'#6561B2',marginLeft:'1%'}}>
                                        <label className="font-montserrat-bold letter-spacing-s button-size-label button-padding-h w-100" style={{textTransform:'initial'}}>Request a Demo</label>
                                    </Button>
                                </ScrollAnimation>
                            </Grid>
                            </Hidden>
                        </Grid>
                       
                    </Grid>
                </Container>
                {/* <div>
                    <Projects data={data.projects} />
                </div> */}
                <Footer />
                <ModalScreen open={open} setOpen={setOpen}/>
            </div>
        )
   
}