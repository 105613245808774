import React from 'react';
import './App.css'
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { Popover, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { productName , productRoute } from './product_json'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const CssTextFieldNew = withStyles({
    root: {
      '& .MuiInput-underline:after': {
        borderBottomColor: 'orange !important',
      },
      '&  .MuiInput-underline:before': {
        borderBottomColor: 'white !important',
      },
      "&.MuiInput-underline:hover:before": {
        borderBottom: "2px solid white !important"
      }
  
    
    },
  })(TextField);

export default function SimplePopover(props) {
  // const theme = useTheme()
  // const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const history = useHistory()
    const classes = useStyles();
    const anchorEl = props.anchorEl
    const setAnchorEl = props.setAnchorEl
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <div >
        <div style={{width:'fit-content',marginRight:'4vw'}} className="Nav-Bar nav-bar-margin" aria-describedby={id} onClick={handleClick}>
          {props.title}
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
            <Grid container spacing={2}  style={{backgroundColor:'#12043F',padding:'20px 20px'}}>
                <Grid item xs={1}>
                    <FontAwesomeIcon   color="white" size='xs' icon={["fas", "search"]} />
                </Grid>
                <Grid item xs={11}>
                <Autocomplete
                    onKeyDown={(event)=>{
                        if(event.keyCode === 13) {
                        history.push("/search?words="+event.target.value.replace(' ','_'))
                        window.location.reload()
                        }
                    }}
                    fullWidth
                    options={productName}
                    getOptionLabel={(option)=>option}
                    onChange={(e)=>{
                        const productIndex = productName.findIndex((title)=>{return title.toLowerCase() === e.target.innerHTML.toLowerCase()})
                        if (productIndex>=0){
                            history.push('product/'+productRoute[productIndex])
                            setAnchorEl(null)
                        }
                    }}
                    classes = {{clearIndicatorDirty: classes.clearIndicator, popupIndicator: classes.popupIndicator, paper: classes.searchPaper, listbox:classes.searchListBox}}
                    renderInput={(params) => (
                        <CssTextFieldNew
                            {...params} 
                            id="web-nav-search" 
                            label="" 
                            InputProps={{
                            style:{
                                color:'white',
                                fontFamily:'Nunito',
                                fontWeight:400

                            }
                            }}
                        />
                    
                    )}
                />
                </Grid>
            </Grid>
        </Popover>
      </div>
    );
  }