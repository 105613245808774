export const searchHome = {
  title:'Home',
  short:'Home',
  searchText:'Home Initiate Possibilities. We are your trusted software engineering partner, with the leverage of smart innovations and cloud technologies, empowering businesses to achieve more in the digital acceleration. ' +
      'At Cerebro, dedication to optimize construction efficiency and quality are more than just business imperatives – we aim to connect traditional construction practice to Construction 2.0, a technological transformation for the industry. ' +
      'What we do? We offer industry-specific solutions from software applications to intelligent 3D modelling, with customization services to ensure our solutions are in compliance with industry standards as well as meeting your unique business needs. ' +
      'Our Customization Process: Our customization process is structured yet flexible to deliver solutions that are best aligned with your unique needs. ' +
      'Products & Services Digital Works Supervision System (DWSS)\n' +
      'Cerebro Inspection\n' +
      'All in one digital platform to achieve paperless and automated inspection.\n' +
      'Digital Works Supervision System (DWSS)\n' +
      'Cerebro Diary\n' +
      'Digital Site Diary platform to record daily site activities, with no hassle.\n' +
      'Cerebro Project – EDMS\n' +
      'Integrated platform to secure, organise and update company documents and drawings.\n' +
      'BIM\n' +
      '3D illustration of full building lifecycle for building assets.\n' +
      'Knowledge Management System\n' +
      'Centralized knowledge sharing platform for all project information and know-hows.\n' +
      'Cerebro Analyse\n' +
      'Electronic construction budgeting and estimation software.\n' +
      'Cerebro Procurement\n' +
      'Full-fledged digital platform to manage vendor and material sourcing.\n' +
      'Cerebro Project\n' +
      'Photo Registration\n' +
      'Affiliated cloud-based photo upload and register mobile application.\n' +
      'Signature App\n' +
      'United signature application for all Cerebro platforms.\n' +
      'Our Clients\n' +
      'CRBC-CEC-KADEN Joint Venter CRBC-Build King Joint Venture BUILD KING - KUM SHING JV REL SKEC',
  description:'Our Mission We are your trusted software engineering partner, with the leverage of smart innovations and cloud technologies, empowering businesses to achieve more in the digital acceleration.',
  path:'/'
}