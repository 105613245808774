export const searchProductEDMS = {
  title:'Electronic Document Management System Cerebro Project',
  short:'EDMS',
  searchText:'Electronic Document Management System Cerebro Project ' +
      'Request a demo ' +
      'An all-in-one platform to centralise, organise and update company documents and drawings. More than a document filing system, our platform combines storage and assignment to ensure hassle free document handling. ' +
      'Unified platform to manage document handling for clients and vendors. ' +
      'Task alerts will be sent specifically to users if actions need to be taken from the users, to make sure tasks are completed correctly and promptly. ' +
      'Highlighted search results shown on result pages facilitate users to efficiently locate the information needed.'+
      'EDMS'+
      'Project Managment'+
      'Document Management'+
      'Web Application',
  description:'An all-in-one platform to centralise, organise and update company documents and drawings. More than a document filing system, our platform combines storage and assignment to ensure hassle free document handling.',
  path:'/product/EDMS'
}