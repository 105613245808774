import './App.css';
import React, { useState} from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { Footer } from './footer'
import { NavBar } from './navbar'
import {useLocation} from "react-router-dom";
import {searchIndex} from './searchTexts/searchIndex'
import { useHistory } from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useWindowSize } from './windowSize'
import { isMobile } from 'react-device-detect';

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: 'orange !important',
    },
    '&  .MuiInput-underline:before': {
      borderBottomColor: 'white !important',
    },
    "&.MuiInput-underline:hover:before": {
      borderBottom: "2px solid white !important"
    }

  
  },
})(TextField);


export function SearchPage(){
    const [height] = useWindowSize();
  

    const history = useHistory()
    function useQuery() {
      return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const [searchResults, setSearchResults] = useState(searchIndex)

    function doSearch(text){
      setSearchResults(searchIndex.filter((e)=>{
        return e.searchText.toLowerCase().indexOf(text) > -1
      }))
    }

    React.useEffect(() => {
      const searchText = query.get('words').replace('_', ' ').toLowerCase()
      doSearch(searchText)
    },[])

    return(
        <div style={{backgroundColor:"white"}}>
            <NavBar />
            <div style={{backgroundColor:'#12043F',height:isMobile?height*0.5:height*0.3}}>
              
              
              <div className="w-100" style={{position:'relative',top:height*0.1}}>
              <Container maxWidth="lg">
                <div style={{padding:'0 4vw'}}>
                  <label style={{marginRight:'5px'}} className="font-montserrat-bold color-white letter-spacing-m font-size-rem-md">Search</label>
                  <label className="font-montserrat-bold color-orange font-size-rem-xl">.</label>
                  <div className="w-100" style={{marginTop:'5%',width:'fit-content'}}>
                    <CssTextField 
                      className="m2 w-100" 

                      id="web-nav-search" 
                      label="" 
                      defaultValue={query.get('words').replace('_', ' ')}
                      InputProps={{
                        endAdornment:<InputAdornment position="end"><FontAwesomeIcon   color="white" size='1x' icon={["fas", "search"]} /></InputAdornment>,
                        style:{
                          color:'white',
                          fontFamily:'Nunito',
                          fontWeight:400
                        }
                      }}
                      onKeyDown={(event)=>{
                        if(event.keyCode === 13) {
                          doSearch(event.target.value.toLowerCase())
                        }
                      }}
                    
                    />
           

                  </div>
                </div>
              </Container>
                
              </div>
            </div>
           
            <Container  maxWidth="lg" style={{overflow:'hidden',backgroundColor:"white"}}>
              {searchResults.length!==0?
              <Grid container spacing={3} direction='column' style={{backgroundColor:'#FFF',padding:isMobile?'20px 4vw':'80px 4vw'}} >
                {searchResults.map((value, index, array) => {
                  return (
                    <Grid item className={'m-2'} key={index}>
                      <div className="h-100"  >
                          <div style={{}}>
                            <label onClick={()=>history.push(value.path)} className="font-montserrat-bold color-blue letter-spacing-s font-size-header search-word" >{value.title}</label>
                          </div>
                          <label className="card-font-data font-nunito-regular color-lightblack letter-spacing-s font-size-xs">{value.description}</label>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
              :<Grid container direction='column' style={{backgroundColor:'#FFF',padding:'5% 5vw'}} spacing={3} >
                    <Grid item>
                      <Typography className="font-montserrat-bold color-blue letter-spacing-s font-size-header" >No Results</Typography>
                    </Grid>
              </Grid>}
            </Container>
            <Footer />
        </div>
    )
}