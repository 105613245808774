export const searchProductPhotoReg = {
  title:'Cerebro Prject Photo Registration',
  short:'Photo Registration',
  searchText:'Cerebro Prject Photo Registration ' +
      'Request a demo ' +
      'Utilizing the cloud technology, all site photos can now be uploaded, stored and categorized through the cloud-based platform with access from anytime and anywhere. Photos stored are retrievable in our Cerebro platforms.'+
      'Project Managment'+
      'Photo Storage'+
      'Site Management'+
      'Mobile Application',
  description:'Utilizing the cloud technology, all site photos can now be uploaded, stored and categorized through the cloud-based platform with access from anytime and anywhere. Photos stored are retrievable in our Cerebro platforms.',
  path:'/product/PhotoReg'
}