export const searchProductSignature = {
  title:'Signature App',
  short:'Signature App',
  searchText:'Signature App ' +
      'Request a demo ' +
      'Make an electronic signature to Cerebro platforms, anytime and anywhere.'+
      'Site Managment'+
      'Project Managment'+
      'E-signature'+
      'Mobile Application',
  description: 'Make an electronic signature to Cerebro platforms, anytime and anywhere.',
  path:'/product/Signature'
}