export const searchProductAnalyse = {
  title:'Cerebro Analyse',
  short:'Cerebro Analyse',
  searchText:'Cerebro Analyse ' +
      'Request a demo ' +
      'A software to streamline construction budgeting and estimations. With a few clicks, monthly and overall costs of sub-contractors and projects will be automatically calculated and ready for review, saving those tedious cost calculations from now on.'+
      'Project Managment'+
      'Budget'+
      'Estimation'+
      'Cost Management'+
      'Web Application',
  description:'A software to streamline construction budgeting and estimations. With a few clicks, monthly and overall costs of sub-contractors and projects will be automatically calculated and ready for review, saving those tedious cost calculations from now on.',
  path:'/product/Analyse'
}