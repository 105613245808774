export const searchProductProcurement = {
  title:'Cerebro Procurement',
  short:'Cerebro Procurement',
  searchText:'Cerebro Procurement ' +
      'Request a demo ' +
      'An integrated platform to automate vendor and material sourcing, digitally. From identifying material costs, screening of best vendors, receipt of quotations and material purchase, every internal and external material movements will always be under your control 24/7, just on one tap away.'+
      'Procurement'+
      'Project Managment'+
      'Sourcing'+
      'Material'+
      'Site Management'+
      'Subcontractor'+
      'Quantity Surveying'+
      'Web Application',
  description:'An integrated platform to automate vendor and material sourcing, digitally. From identifying material costs, screening of best vendors, receipt of quotations and material purchase, every internal and external material movements will always be under your control 24/7, just on one tap away.',
  path:'/product/Procurement'
}