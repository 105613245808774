import './App.css';
import React,{ useState, useEffect,useRef } from 'react';
import {  Container, Hidden, Grid,useMediaQuery,GridList } from '@material-ui/core';
import Process from './assets/image/process.png'
import Process_m from './assets/image/process_mobile.png'
import anime from 'animejs/lib/anime.es.js';
import { useTheme } from '@material-ui/core/styles';
import Test1 from './assets/image/test1@2x.png'
import Test2 from './assets/image/test2@2x.png'
import Test3 from './assets/image/test3@2x.png'
import { makeStyles } from '@material-ui/core/styles';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  }));




export function SecondSection(props){
    const [check, setCheck ] = useState(false)
    const classes = useStyles();
    const theme = useTheme();
    const animateTime = 50
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const animationRef = useRef(null);
    const animationRef1 = useRef(null);
    const animationRef2 = useRef(null);
    const animationRef3 = useRef(null);
    const animationRef4 = useRef(null);
    const animationRef5 = useRef(null);
    const animationRef6 = useRef(null);
    const animationRef7 = useRef(null);
    const animationRef8 = useRef(null);
     
          
    useEffect(() => {
        var basicTimeline = anime.timeline({
            autoplay: false
          });
        var basicTimeline2 = anime.timeline({
        autoplay: false
        });
        var basicTimeline3 = anime.timeline({
        autoplay: false
        })
        var basicTimeline4 = anime.timeline({
            autoplay: false
        });
        var basicTimeline5 = anime.timeline({
            autoplay: false
        });
        var basicTimeline6 = anime.timeline({
            autoplay: false
        })
        
        var basicTimeline7 = anime.timeline({
            autoplay: false
            });
        var basicTimeline8 = anime.timeline({
        autoplay: false
        });
        var basicTimeline9 = anime.timeline({
        autoplay: false
        })
        animationRef.current =  basicTimeline
        .add({
            targets: "#Analysis-img",
            duration:animateTime,
            translateX: '-17rem',
            filter: 'brightness(1)',
            easing:'easeOutQuad',
            
          }).add({
            targets:"#Analysis-header",
            duration:animateTime,
            translateX:-50,
            easing:'easeInQuad',
            opacity:0
        }).add({
            targets: ".Analysis",
            duration: 100,
            backgroundColor: '#FFFFFF',
          }).add({
            targets: "#Analysis-des",
            duration: 100,
            opacity:1,
            translateX:-30,
          })

          animationRef1.current =  basicTimeline2
          .add({
              targets: "#Development-img",
              duration:animateTime,
              filter: 'brightness(1)',
              translateX: '-16.5rem',
              easing:'easeOutQuad',
              
            }).add({
              targets:"#Development-header",
              duration:animateTime,
              translateX:-50,
              easing:'easeInQuad',
              opacity:0
          }).add({
              targets: ".Development",
              duration: 100,
              backgroundColor: '#FFFFFF',
            }).add({
              targets: "#Development-des",
              duration: 100,
              opacity:1,
              translateX:-30,
            })


            animationRef2.current =  basicTimeline3
          .add({
              targets: "#Support-img",
              duration:animateTime,
              filter: 'opacity(1)',
              translateX: '-17rem',
              easing:'easeOutQuad',
              
            }).add({
              targets:"#Support-header",
              duration:animateTime,
              translateX:-50,
              easing:'easeInQuad',
              opacity:0
          }).add({
              targets: ".Support",
              duration: 100,
              backgroundColor: '#FFFFFF',
            }).add({
              targets: "#Support-des",
              duration: 100,
              opacity:1,
              translateX:-30,
            })

            animationRef3.current =  basicTimeline4
        .add({
            targets: "#Analysis-img1",
            duration:animateTime,
            filter: 'brightness(1)',
            translateX: '-300px',
            easing:'easeOutQuad',
            
          }).add({
            targets:"#Analysis-header",
            duration:animateTime,
            translateX:-50,
            easing:'easeInQuad',
            opacity:0
        }).add({
            targets: ".Analysis",
            duration: 100,
            backgroundColor: '#FFFFFF',
          }).add({
            targets: "#Analysis-des",
            duration: 100,
            opacity:1,
            translateX:-30,
          })

          animationRef4.current =  basicTimeline5
          .add({
              targets: "#Development-img1",
              duration:animateTime,
              filter: 'brightness(1)',
              translateX: '-300px',
              easing:'easeOutQuad',
              
            }).add({
              targets:"#Development-header",
              duration:animateTime,
              translateX:-50,
              easing:'easeInQuad',
              opacity:0
          }).add({
              targets: ".Development",
              duration: 100,
              backgroundColor: '#FFFFFF',
            }).add({
              targets: "#Development-des",
              duration: 100,
              opacity:1,
              translateX:-30,
            })


            animationRef5.current =  basicTimeline6
          .add({
              targets: "#Support-img1",
              duration:animateTime,
              filter: 'opacity(1)',
              translateX: '-300px',
              easing:'easeOutQuad',
              
            }).add({
              targets:"#Support-header",
              duration:animateTime,
              translateX:-50,
              easing:'easeInQuad',
              opacity:0
          }).add({
              targets: ".Support",
              duration: 100,
              backgroundColor: '#FFFFFF',
            }).add({
              targets: "#Support-des",
              duration: 100,
              opacity:1,
              translateX:-30,
            })

            animationRef6.current =  basicTimeline7
        .add({
            targets: "#Analysis-img2",
            duration:animateTime,
            filter: 'brightness(1)',
            translateX: '-60vw',
            easing:'easeOutQuad',
            
          }).add({
            targets:"#Analysis-header",
            duration:animateTime,
            translateX:-50,
            easing:'easeInQuad',
            opacity:0
        }).add({
            targets: ".Analysis",
            duration: 100,
            backgroundColor: '#FFFFFF',
          }).add({
            targets: "#Analysis-des",
            duration: 100,
            opacity:1,
            translateX:-20,
          })

          animationRef7.current =  basicTimeline8
          .add({
              targets: "#Development-img2",
              duration:animateTime,
              filter: 'brightness(1)',
              translateX: '-60vw',
              easing:'easeOutQuad',
              
            }).add({
              targets:"#Development-header",
              duration:animateTime,
              translateX:-50,
              easing:'easeInQuad',
              opacity:0
          }).add({
              targets: ".Development",
              duration: 100,
              backgroundColor: '#FFFFFF',
            }).add({
              targets: "#Development-des",
              duration: 100,
              opacity:1,
              translateX:-30,
            })


            animationRef8.current =  basicTimeline9
          .add({
              targets: "#Support-img2",
              duration:animateTime,
              filter: 'opacity(1)',
              translateX: '-60vw',
              easing:'easeOutQuad',
              
            }).add({
              targets:"#Support-header",
              duration:animateTime,
              translateX:-50,
              easing:'easeInQuad',
              opacity:0
          }).add({
              targets: ".Support",
              duration: 100,
              backgroundColor: '#FFFFFF',
            }).add({
              targets: "#Support-des",
              duration: 100,
              opacity:1,
              translateX:-30,
            })
     
          
      }, []);
      
    

      const handleIn = (animationRef) => {
        if(animationRef.current.direction === 'reverse'){
            animationRef.current.reverse()
        }
        animationRef.current.restart()
      }

      const handleOut = (animationRef) => {
        if(animationRef.current.direction === 'normal'){
            animationRef.current.reverse()
            animationRef.current.play()
        }else{
            animationRef.current.play()
        }}
      
    return(
       
        <div ref={props.reference}>
            <div className="Mission" style={{padding:'0 4vw'}}>
                <Container maxWidth="lg">
                    <Grid container
                        justify="center"
                        direction="column"
                        alignItems="center"
                    >
                        <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                            <Grid item xs={12}>
                                <Grid container justify="center" style={{marginTop:'80px'}}>
                                    <label className="font-montserrat-bold color-white letter-spacing-m font-size-rem-md margin-header-content">Our Mission</label>
                                </Grid>
                                <Grid container justify="center">
                                    <label className="font-montserrat-bold color-orange  font-size-rem-lg">”</label>
                                </Grid>
                                <Grid container justify="center" >
                                    <label style={{padding:'2vh 5vw 80px 5vw'}} className="font-nunito-semibolditalic color-white letter-spacing-s font-size-rem-xs text-center">At Cerebro, dedication to optimize construction efficiency and quality are more than just business imperatives – we aim to connect traditional construction practice to Construction 2.0, a technological transformation for the industry.</label>
                                </Grid>
                            </Grid>
                        </ScrollAnimation>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth="lg" >
                <div className="Content-Container"  style={{padding:'0 4vw'}}>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                        <Grid container direction="column" justify="center" style={{}}>
                            <label className="font-montserrat-bold color-orange font-size-rem-xl margin-dot-top">.</label>
                            <label className="font-montserrat-bold color-black letter-spacing-m font-size-header margin-header-content" style={{}}>What we do?</label>
                            <label className="font-nunito-regular color-black letter-spacing-s font-size-des " >We offer industry-specific solutions from software applications to intelligent 3D modelling, with customization services to ensure our solutions are in compliance with industry standards as well as meeting your unique business needs. </label>
                        </Grid>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                        <div className="w-100 margin-nodot-top" style={{position:'relative'}}>
                            <Grid style={{display:sm||md?'flex':'none'}} container spacing={3} >
                                <GridList className={classes.gridList} cols={2.5} spacing={12} cellHeight='auto' >
                                    <Grid item xs={12} sm={4}  style={{minWidth:'380px'}}
                                        id="Analysis"
                                        >
                                            <Grid container
                                                onMouseEnter={()=>handleIn(animationRef3)} onMouseLeave={()=>handleOut(animationRef3)} 
                                                className="rounded Analysis card-box shadow-sm h-100" style={{overflow:'hidden',width:'100%'}} justify="center" alignItems="center" >
                                                <Grid item xs={5}>
                                                    <div id="Analysis-header"  style={{paddingLeft:'5%'}} >
                                                        <label  className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xs">Analysis </label>
                                                        <label style={{marginLeft:'5px'}} className="color-orange font-montserrat-bold color-orange font-size-rem-sm"> .</label>
                                                    </ div>
                                                </Grid>
                                                <Grid item xs={7} style={{}}>
                                                    <div id="Analysis-des"  style={{position:'absolute',top:'1rem',opacity:0}}>
                                                        <div>
                                                        <label  className="font-montserrat-bold color-black letter-spacing-m card-header-font-size " >Analysis </label>
                                                        <label style={{marginLeft:'5px'}} className="color-orange font-montserrat-bold color-orange font-size-rem-sm ">.</label>
                                                        </div>
                                                        <div style={{}}>
                                                            <label className="font-nunito-regular color-black letter-spacing-s card-data-font-size">Every project is different, so as the solution. Our experts are committed to analyze every case,  communicate proactively with the customers, identify the underlying digital needs and provide the best yet appropriate solution to our customers. </label>
                                                        </div>
                                                    </div>
                                                    <Grid container style={{}} alignContent={'center'}>
                                                    <Grid item>
                                                        <img id="Analysis-img1" style={{width:'100%',filter:'brightness(0.9)',position:'relative',left:'4vw'}} src={Test1} alt="" />
                                                    </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}  style={{minWidth:'380px'}}
                                        id="Development"
                                        >
                                            <Grid
                                                onMouseEnter={()=>handleIn(animationRef4)} onMouseLeave={()=>handleOut(animationRef4)} 
                                                container className="rounded Development card-box shadow-sm h-100" style={{overflow:'hidden',width:'100%'}} justify="center" alignItems="center" >
                                                <Grid item xs={5}>
                                                    <div id="Development-header"  style={{paddingLeft:'5%'}} >
                                                        <label  className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xs">Development</label>
                                                        <label style={{marginLeft:'5px'}} className="color-black font-montserrat-bold color-blue font-size-rem-sm">.</label>
                                                    </ div>
                                                </Grid>
                                                <Grid item xs={7} style={{}}>
                                                    <div id="Development-des"  style={{position:'absolute',top:'1rem',opacity:0}}>
                                                        <label  className="font-montserrat-bold color-black letter-spacing-m card-header-font-size " >Development </label>
                                                        <label style={{marginLeft:'5px'}} className="color-black font-montserrat-bold color-blue font-size-rem-sm ">.</label>
                                                        <div style={{}}>
                                                            <label className="font-nunito-regular color-black letter-spacing-s card-data-font-size">With a significant background of construction and technology, our professionals will be involved in the entire development process from sketch to delivery, making sure the platforms fully adhere to customers’ expectations and their site practice. </label>
                                                        </div>
                                                    </div>
                                                    <Grid container style={{}} alignContent={'center'}>
                                                    <Grid item>
                                                        <img id="Development-img1" style={{width:'100%',filter:'brightness(0.9)',position:'relative',left:'4vw'}} src={Test2} alt=""/>
                                                    </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4} id="Support" style={{minWidth:'380px'}}>
                                        <Grid 
                                            onMouseEnter={()=>handleIn(animationRef5)} onMouseLeave={()=>handleOut(animationRef5)} 
                                            container className="rounded Support card-box shadow-sm h-100" style={{overflow:'hidden',width:'100%'}} justify="center" alignItems="center" >
                                                <Grid item xs={5}>
                                                    <div id="Support-header"  style={{paddingLeft:'5%'}} >
                                                        <label  className="font-montserrat-bold color-black letter-spacing-m font-size-rem-xs">After Sales </label>
                                                        <br></br>
                                                        <label className="font-montserrat-bold color-black letter-spacing-m font-size-rem-xs"> Support </label>
                                                        <label style={{marginLeft:'5px'}} className="color-lightpurple font-montserrat-bold font-size-rem-sm "> .</label>
                                                    </ div>
                                                </Grid>
                                                <Grid item xs={7} style={{}}>
                                                    <Grid container direction="column" alignItems="center" >

                                                    <div id="Support-des"  style={{position:'absolute',opacity:0,top:'1rem',width:'100%'}}>
                                                        <div style={{width:'130%'}}>
                                                            <label  className="font-montserrat-bold color-black letter-spacing-m card-header-font-size" >After Sales Support</label>
                                                            <label style={{marginLeft:'5px'}} className="color-lightpurple font-montserrat-bold color-orange font-size-rem-sm ">.</label>
                                                        </div>
                                                        <div style={{width:'110%'}}>
                                                            <label className="font-nunito-regular color-black letter-spacing-s card-data-font-size">Our platforms aren’t the only thing we greatly value – user experience is the key to maintain a sustainable platform. Our support team will be happy to assist users with any difficulties arising from using Cerebro platforms, ensuring a smooth user experience.</label>
                                                        </div>
                                                    </div>
                                                    </Grid>
                                                    <Grid container style={{}}  alignContent={'center'}>
                                                    <Grid item>
                                                        <img id="Support-img1" style={{width:'100%',filter:'opacity(0.4)',position:'relative',left:'4vw'}} src={Test3} alt="" />
                                                    </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                </GridList>
                                </Grid>
                                <Grid  style={{display:xl||lg?'flex':'none'}}  container spacing={3} >
                                    <Grid item xs={12} sm={4}  style={{minWidth:'290px'}}
                                        id="Analysis"
                                        >
                                            <Grid container
                                                onMouseEnter={()=>handleIn(animationRef)} onMouseLeave={()=>handleOut(animationRef)} 
                                                className="rounded Analysis card-box shadow-sm h-100" style={{overflow:'hidden',width:'100%'}} justify="center" alignItems="center" >
                                                <Grid item xs={5}>
                                                    <div id="Analysis-header"  style={{paddingLeft:'5%'}} >
                                                        <label  className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xs">Analysis </label>
                                                        <label style={{marginLeft:'5px'}} className="color-orange font-montserrat-bold color-orange font-size-rem-sm"> .</label>
                                                    </ div>
                                                </Grid>
                                                <Grid item xs={7} style={{}}>
                                                    <div id="Analysis-des"  style={{position:'absolute',opacity:0}}>
                                                        <div>
                                                        <label  className="font-montserrat-bold color-black letter-spacing-m card-header-font-size" >Analysis </label>
                                                        <label style={{marginLeft:'5px'}} className="color-orange font-montserrat-bold color-orange font-size-rem-md">     .</label>
                                                        </div>
                                                        <div style={{}}>
                                                            <label className="font-nunito-regular color-black letter-spacing-s card-data-font-size">Every project is different, so as the solution. Our experts are committed to analyze every case,  communicate proactively with the customers, identify the underlying digital needs and provide the best yet appropriate solution to our customers. </label>
                                                        </div>
                                                    </div>
                                                    <Grid container style={{}} alignContent={'center'}>
                                                    <Grid item>
                                                        <img id="Analysis-img" style={{width:'100%',filter:'brightness(0.9)',position:'relative',left:'2vw'}} src={Test1} alt="" />
                                                    </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}  style={{minWidth:'290px'}}
                                        id="Development"
                                        >
                                            <Grid
                                                onMouseEnter={()=>handleIn(animationRef1)} onMouseLeave={()=>handleOut(animationRef1)} 
                                                container className="rounded Development card-box shadow-sm h-100" style={{overflow:'hidden',width:'100%'}} justify="center" alignItems="center" >
                                                <Grid item xs={5}>
                                                    <div id="Development-header"  style={{paddingLeft:'5%',width:'120%'}} >
                                                        <label  className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xs" >Development</label>
                                                        <label style={{marginLeft:'5px'}} className="color-black font-montserrat-bold color-blue font-size-rem-sm">.</label>
                                                    </ div>
                                                </Grid>
                                                <Grid item xs={7} style={{}}>
                                                    <div id="Development-des"  style={{position:'absolute',opacity:0}}>
                                                        <label  className="font-montserrat-bold color-black letter-spacing-m card-header-font-size" >Development </label>
                                                        <label style={{marginLeft:'5px'}} className="color-blue font-montserrat-bold color-black font-size-rem-md ">.</label>
                                                        <div style={{}}>
                                                            <label className="font-nunito-regular color-black letter-spacing-s card-data-font-size">With a significant background of construction and technology, our professionals will be involved in the entire development process from sketch to delivery, making sure the platforms fully adhere to customers’ expectations and their site practice. </label>
                                                        </div>
                                                    </div>
                                                    <Grid container style={{}} alignContent={'center'}>
                                                    <Grid item>
                                                        <img id="Development-img" style={{width:'100%',filter:'brightness(0.9)',position:'relative',left:'2vw'}} src={Test2} alt="" />
                                                    </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4} id="Support" style={{minWidth:'290px'}}>
                                        <Grid 
                                            onMouseEnter={()=>handleIn(animationRef2)} onMouseLeave={()=>handleOut(animationRef2)} 
                                            container className="rounded Support card-box shadow-sm h-100" style={{overflow:'hidden',width:'100%'}} justify="center" alignItems="center" >
                                                <Grid item xs={5}>
                                                    <div id="Support-header"  style={{paddingLeft:'5%'}} >
                                                        <label  className="font-montserrat-bold color-black letter-spacing-m font-size-rem-xs">After Sales </label>
                                                        <br></br>
                                                        <label className="font-montserrat-bold color-black letter-spacing-m font-size-rem-xs"> Support </label>
                                                        <label style={{marginLeft:'5px'}} className="color-lightpurple font-montserrat-bold font-size-rem-sm "> .</label>
                                                    </ div>
                                                </Grid>
                                                <Grid item xs={7} style={{}}>
                                                    
                                                    <div id="Support-des"  style={{position:'absolute',opacity:0,width:'100%'}}>
                                                        <div style={{width:'130%'}}>
                                                            <label  className="font-montserrat-bold color-black letter-spacing-m card-header-font-size" >After Sales Support</label>
                                                            <label style={{marginLeft:'5px'}} className="color-lightpurple font-montserrat-bold font-size-rem-md ">.</label>
                                                        </div>
                                                        <div style={{width:'110%'}}>
                                                            <label className="font-nunito-regular color-black letter-spacing-s card-data-font-size">Our platforms aren’t the only thing we greatly value – user experience is the key to maintain a sustainable platform. Our support team will be happy to assist users with any difficulties arising from using Cerebro platforms, ensuring a smooth user experience.</label>
                                                        </div>
                                                    </div>
                                                    <Grid container style={{}}  alignContent={'center'}>
                                                        <Grid item>
                                                            <img id="Support-img" style={{width:'100%',filter:'opacity(0.4)',position:'relative',left:'2vw'}} src={Test3} alt="" />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    </Grid>
                                    <Grid style={{display:xs?'flex':'none'}} container spacing={3} >
                                    <Grid item xs={12} sm={4}  style={{height:'fit-content'}}
                                        id="Analysis"
                                        >
                                            <Grid container
                                                onMouseEnter={()=>handleIn(animationRef6)} onMouseLeave={()=>handleOut(animationRef6)} 
                                                className="rounded Analysis card-box shadow-sm h-100" style={{overflow:'hidden',width:'100%'}} justify="center" alignItems="center" >
                                                <Grid item xs={5}>
                                                    <div id="Analysis-header"  style={{paddingLeft:'5%'}} >
                                                        <label  className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xs">Analysis </label>
                                                        <label style={{marginLeft:'5px'}} className="color-orange font-montserrat-bold color-orange font-size-rem-sm"> .</label>
                                                    </ div>
                                                </Grid>
                                                <Grid item xs={7} style={{}}>
                                                    <div id="Analysis-des"  style={{position:'absolute',opacity:0}}>
                                                        <div>
                                                            <label  className="font-montserrat-bold color-black letter-spacing-m card-header-font-size" >Analysis </label>
                                                            <label style={{marginLeft:'5px',position:'absolute',top:'-8px'}} className="color-orange font-montserrat-bold color-orange font-size-rem-sm">.</label>
                                                        </div>
                                                        <div style={{width:'105%'}}>
                                                            <label className="font-nunito-regular color-black letter-spacing-s card-data-font-size">Every project is different, so as the solution. Our experts are committed to analyze every case,  communicate proactively with the customers, identify the underlying digital needs and provide the best yet appropriate solution to our customers. </label>
                                                        </div>
                                                    </div>
                                                    <Grid container  style={{padding:'5% 0%'}} alignContent={'center'}>
                                                    <Grid item>
                                                        <img id="Analysis-img2" style={{width:'100%',filter:'brightness(0.9)',position:'relative',left:'4vw'}} src={Test1} alt="" />
                                                    </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4}  style={{height:'fit-content'}}
                                        id="Development"
                                        >
                                            <Grid
                                                onMouseEnter={()=>handleIn(animationRef7)} onMouseLeave={()=>handleOut(animationRef7)} 
                                                container className="rounded Development card-box shadow-sm h-100" style={{overflow:'hidden',width:'100%'}} justify="center" alignItems="center" >
                                                <Grid item xs={5}>
                                                    <div id="Development-header"  style={{paddingLeft:'5%',width:'120%'}} >
                                                        <label className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xs">Development</label>
                                                        <label style={{marginLeft:'5px'}} className="color-black font-montserrat-bold color-blue font-size-rem-sm">.</label>
                                                    </ div>
                                                </Grid>
                                                <Grid item xs={7} style={{}}>
                                                    <div id="Development-des"  style={{position:'absolute',opacity:0}}>
                                                        <label  className="font-montserrat-bold color-black letter-spacing-m card-header-font-size" >Development </label>
                                                        <label style={{marginLeft:'5px',position:'absolute',top:'-8px'}} className="color-black font-montserrat-bold color-blue font-size-rem-sm">.</label>
                                                        <div style={{width:'105%'}}>
                                                            <label className="font-nunito-regular color-black letter-spacing-s card-data-font-size">With a significant background of construction and technology, our professionals will be involved in the entire development process from sketch to delivery, making sure the platforms fully adhere to customers’ expectations and their site practice. </label>
                                                        </div>
                                                    </div>
                                                    <Grid container  style={{padding:'5% 0%'}}alignContent={'center'}>
                                                    <Grid item>
                                                        <img id="Development-img2" style={{width:'100%',filter:'brightness(0.9)',position:'relative',left:'4vw'}} src={Test2} alt="" />
                                                    </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4} id="Support" style={{height:'fit-content'}}>
                                        <Grid 
                                            onMouseEnter={()=>handleIn(animationRef8)} onMouseLeave={()=>handleOut(animationRef8)} 
                                            container className="rounded Support card-box shadow-sm h-100" style={{overflow:'hidden',width:'100%'}} justify="center" alignItems="center" >
                                                <Grid item xs={5}>
                                                    <div id="Support-header"  style={{paddingLeft:'5%'}} >
                                                        <label  className="font-montserrat-bold color-black letter-spacing-m font-size-rem-xs">After Sales </label>
                                                        <br></br>
                                                        <label className="font-montserrat-bold color-black letter-spacing-m font-size-rem-xs"> Support </label>
                                                        <label style={{marginLeft:'5px'}} className="color-lightpurple font-montserrat-bold font-size-rem-sm"> .</label>
                                                    </ div>
                                                </Grid>
                                                <Grid item xs={7} style={{}}>
                                                    <div id="Support-des"  style={{position:'absolute',opacity:0}}>
                                                        <div style={{width:'110%'}}>
                                                            <label  className="font-montserrat-bold color-black letter-spacing-m card-header-font-size" >After Sales Support</label>
                                                            <label style={{marginLeft:'5px',position:'absolute',top:'-8px'}} className="color-lightpurple font-montserrat-bold font-size-rem-sm">.</label>
                                                        </div>
                                                        <div style={{width:'105%'}}>
                                                            <label className="font-nunito-regular color-black letter-spacing-s card-data-font-size">Our platforms aren’t the only thing we greatly value – user experience is the key to maintain a sustainable platform. Our support team will be happy to assist users with any difficulties arising from using Cerebro platforms, ensuring a smooth user experience.</label>
                                                        </div>
                                                    </div>
                                                    <Grid container style={{padding:'5% 0%'}}  alignContent={'center'}>
                                                    <Grid item>
                                                        <img id="Support-img2" style={{width:'100%',filter:'opacity(0.4)',position:'relative',left:'4vw'}} src={Test3} alt="" />
                                                    </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                            </Grid>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                        <label style={{}} className="font-montserrat-bold color-orange letter-spacing-m font-size-header Process margin-nodot-top margin-header-content">Our Customization Process:</label>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                        <div style={{}}>
                            <label className="font-nunito-regular color-black letter-spacing-s font-size-des" >Our customization process is structured yet flexible to deliver solutions that are best aligned with your unique needs. </label>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                        <Hidden smUp>
                            <img style={{}} className="Process-Image margin-nodot-top" src={Process_m} alt="" />
                        </Hidden>
                            
                        <Hidden xsDown>
                            <img style={{}} className="Process-Image margin-nodot-top" src={Process} alt="" />
                        </Hidden>
                    </ScrollAnimation>
                </div>
            </Container>
        </div>
       
    )
}