import './App.css';
import React from 'react';
import { Grid,Hidden,useMediaQuery,Container } from '@material-ui/core';
import Cerebro from './assets/image/cerebro_grey.png'
import { useHistory } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IG from './assets/image/ig.png'
import TC from './assets/pdf/Terms&Conditions.pdf'
import PP from './assets/pdf/PrivacyPolicy.pdf'
import dayjs from 'dayjs';

export function Footer(props){
    const history = useHistory()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const year = dayjs().year()
    return(
        <div style={{backgroundColor:'#EDEDED'}}>
            <Container maxWidth="lg" ref={props.reference}>
             <div className="Content-footer" style={{paddingLeft:'4vw',paddingRight:'4vw'}}>
                <Grid container spacing={3  }>
                    <Grid 
                    item  xs={12} sm={4} >
                        <Grid container >
                            <Grid item xs={12} >
                                <img style={{width:'10rem'}} src={Cerebro} alt="Cerebro" className="margin-footer-l" />
                            </Grid>
                            <Grid  style={{}}
                            item  xs={12} >
                                <Grid container>
                                     <Grid item xs={2}>
                                        <FontAwesomeIcon  color="grey" style={{marginRight:'2vw'}} icon={["fas", "envelope"]} />
                                     </Grid>
                                     <Grid item xs={10}>
                                        <label className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm margin-footer-s">info@cerebrohk.com</label>
                                     </Grid>
                                 </Grid>
                                 {/* <MdEmail color="grey" style={{alignSelf:'center',paddingRight:matches?'0%':'5vw'}}/>
                                <label className="Foot-Font">info@cerebrohk.com</label> */}
                            </Grid>
                            {/* <Grid style={{}}
                            item xs={12} >
                                <Grid container>
                                     <Grid item xs={2}>
                                        <FontAwesomeIcon  color="grey" style={{marginRight:'2vw '}} icon={["fas", "phone-alt"]} />
                                     </Grid>
                                     <Grid item xs={10}>
                                        <label className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm margin-footer-s">852 2272 3774</label>
                                     </Grid>
                                 </Grid>
                                
                            </Grid> */}
                            <Grid  style={{}}
                             item xs={12}>
                                 <Grid container>
                                     <Grid item xs={2}>
                                        <FontAwesomeIcon  color="grey" style={{marginRight:'2vw '}} icon={["fas", "map-marker-alt"]} />
                                     </Grid>
                                     <Grid item xs={10}>
                                        <label className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm margin-footer-s" >Unit F, 11/F, MG Tower, 133 Hoi Bun Road, Kwun Tong, Kowloon, Hong Kong</label>

                                     </Grid>
                                 </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Hidden xsDown>
                            <Grid container
                                 direction="column">
                                <label style={{}} onClick={() => history.push("/about")} className="font-montserrat-bold color-black letter-spacing-s footer-hover font-size-px-sm margin-footer-l">About Us</label>
                                <label style={{}} onClick={()=>history.push("/?jump=our_clients")} className="font-montserrat-bold color-black letter-spacing-s footer-hover font-size-px-sm margin-footer-l" >Our Clients</label>
                                <label style={{}} className="font-montserrat-bold color-black letter-spacing-s font-size-px-sm margin-footer-s">Follow Us</label>
                                <div>
                                     <FontAwesomeIcon style={{margin:'-7px 15px -7px 0px'}}   onClick={()=>window.open('https://www.facebook.com/Cerebro-Strategy-Limited-103726845253451/', "_blank")} className="fb-hover" size="2x"   color="grey" icon={["fab", "facebook"]} />
                                <img src={IG} onClick={()=>window.open('https://www.instagram.com/cerebrostrategylimited/', "_blank")} style={{fill:'grayscale(0)'}} className="instagram" alt="" />
                                    <FontAwesomeIcon className="u2-hover"  size="2x" style={{margin:'-7px 0px -7px 15px'}}   onClick={()=>window.open('https://www.youtube.com/channel/UCorXExRHKUKGoBsOtRZkzCQ/featured', "_blank")} color="grey"  icon={["fab", "youtube"]} />
                                </div>
                                       
                                   
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid container direction="column" style={{}}>
                                <label style={{}} className="font-montserrat-bold color-black letter-spacing-s margin-footer-s">Follow Us</label>
                                <div>
                                    <FontAwesomeIcon style={{margin:'-7px 15px -7px 0px'}}  onClick={()=>window.open('https://www.facebook.com/Cerebro-Strategy-Limited-103726845253451/', "_blank")} className="fb-hover" size="2x"   color="grey" icon={["fab", "facebook"]} />
                                    <img src={IG} onClick={()=>window.open('https://www.instagram.com/cerebrostrategylimited/', "_blank")} style={{fill:'grayscale(0)'}} className="instagram" alt="" />
                                    <FontAwesomeIcon className="u2-hover"  size="2x" style={{margin:'-7px 0px -7px 15px'}}   onClick={()=>window.open('https://www.youtube.com/channel/UCorXExRHKUKGoBsOtRZkzCQ/featured', "_blank")} color="grey"  icon={["fab", "youtube"]} />
                                </div>
                                   

                            </Grid>
                        </Hidden>
                        
                    </Grid>
                    <Hidden xsDown>
                        <Grid item  xs={12} sm={6} >
                            <Grid container
                                direction="column" justify="center" >
                                <label style={{}} className="font-montserrat-bold color-black letter-spacing-s font-size-px-sm margin-footer-l">Products & Services</label>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} >
                                            <label onClick={() => history.push("/product/Inspection")} className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm footer-hover">DWSS - Cerebro Inspection</label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label onClick={() => history.push("/product/Diary")} className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm footer-hover">Cerebro Analyse</label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label onClick={() => history.push("/product/EDMS")} className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm footer-hover">DWSS - Cerebro Diary </label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label onClick={() => history.push("/product/BIM")} className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm footer-hover">Cerebro Procurement </label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label onClick={() => history.push("/product/KMS")} className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm footer-hover">EDMS - Cerebro Project </label>
                                        </Grid>
                                        <Grid item xs={6}  >
                                            <label onClick={() => history.push("/product/Analyse")} className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm footer-hover">Cerebro Project - Photo Registration</label>
                                        </Grid>
                                        <Grid item xs={6}  >
                                            <label onClick={() => history.push("/product/Procurement")} className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm footer-hover">Building Information Modelling</label>
                                        </Grid>
                                        <Grid item xs={6}  >
                                            <label onClick={() => history.push("/product/PhotoReg")} className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm footer-hover">Cerebro Signature App</label>
                                        </Grid>
                                        <Grid item xs={6}  >
                                            <label onClick={() => history.push("/product/Signature")} className="font-nunito-semibold color-grey letter-spacing-s font-size-px-sm footer-hover">Cerebro Knowledge Management System</label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                 
                </Grid>
            </div>
            </Container>
            <div className="Content-footer-copyRight" >
            <Container maxWidth="lg" >
                <Grid style={{paddingLeft:'3vw',paddingRight:'3vw'}} container >
                    <Grid item xs={12} sm={8}>
                        <label  className='font-size-px-sm CopyRight'>© Cerebro Strategy Limited {year}. All Rights Reserved. </label>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <Grid container justify={matches?"flex-end":"flex-start"}>
                            <a href = {TC} target = "_blank" rel="noreferrer">
                                <label className="font-nunito-regular font-size-px-sm footer-hover color-grey">Terms and Conditions</label>
                            </a>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Grid container justify="flex-end">
                            {/* <a href = {PP} target = "_blank" rel="noreferrer"> */}
                                <label onClick={()=> history.push("/privacypolicy")} className="font-nunito-regular font-size-px-sm footer-hover color-grey">Privacy Policy</label>
                            {/* </a> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            </div>
        
        </div>
    )
}