import './App.css';
import React, { useEffect } from 'react';
import { Home } from './home'
import { AboutUs } from './about'
import { ContactUs } from './contact'
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Product } from './product';
import { SearchPage } from './searchPage'
import './components/assets/base.scss';
import ScrollToTop from './scrollToTop'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { PrivacyPolicy } from './pp';
import { NavBar} from './navbar'


function AnimationApp() {
  let location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>
          <Route exact path="/product/:name">
            <Product />
          </Route>
          <Redirect to="/" />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}
function App() {
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, [])
  return (
    // <ThemeProvider theme={MuiTheme}>
    <Router >
      <ScrollToTop />
      <NavBar />
      <div className="Container">


        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <AboutUs />
          </Route>
          <Route exact path="/contact">
            <ContactUs />
          </Route>
          <Route exact path="/search">
            <SearchPage />
          </Route>
          <Route exact path="/privacypolicy">
            <PrivacyPolicy />
          </Route>
          <Route path="*">
            <AnimationApp />
          </Route>
          <Route component={Home} />
          <Redirect to="/" />
        </Switch>

      </div>
    </Router>
    // </ThemeProvider>
  );
}

export default App;
