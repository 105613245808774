export const searchProductDiary = {
  title:'Digital Works Supervision System (DWSS) Cerebro Diary',
  short:'Cerebro Diary',
  searchText:'Digital Works Supervision System (DWSS) Cerebro Diary ' +
      'Request a demo ' +
      'A paperless site diary platform to record daily construction records. With a few steps, site staff will be able to record every site details ranging from manpower to equipment, along with report export function to generate comprehensive reports which are in compliance with construction practice. ' +
      'Create, record, review and endorse site diary form digitally with any device, any time and anywhere. ' +
      'Support on site attendance record synchronized to Cerebro Diary. ' +
      'Weather data is extracted from the local weather observatory and prefilled on a digital site diary. ' +
      'Reports can be generated and exported as GF527 and GF527A reports on the monthly return of construction site employment statistics.'+
      'Site Diary'+
      'Site Managment'+
      'DWSS'+
      'Safety'+
      'Quality'+
      'Site Activities'+
      'Labour Management'+
      'Web Application',
  description:'A paperless site diary platform to record daily construction records. With a few steps, site staff will be able to record every site details ranging from manpower to equipment, along with report export function to generate comprehensive reports which are in compliance with construction practice.',
  path:'/product/Diary'
}