

import './App.css';
import React, {useState,useLayoutEffect,useEffect, useRef} from 'react';
import {  Container , Hidden, Modal } from '@material-ui/core';
import { FirstSection } from './firstSection'
import { SecondSection } from './secondSection'
import { ThirdSection } from './thirdSection'
import { FourthSection } from './fourthSection'
import { FifthSection } from './fifthSection'
import { NavBar } from './navbar'
import { Footer } from './footer'
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
// import {useLocation} from "react-router-dom";
import Lottie from 'react-lottie';
import { opening as animationData  } from './animation'
import { useWindowSize } from './windowSize'
// import { useTheme } from '@material-ui/core/styles';


export function Home() {
  // const theme = useTheme()
  const [width, height] = useWindowSize();
  // const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const [open, setOpen ] = useState(true)
  const [modalOpen, setModalOpen] = useState(false);

  const firstSecRef = useRef()
  const secondSecRef = useRef()
  const thirdSecRef = useRef()
  const fourthSecRef = useRef()
  const fifthSecRef = useRef()
  const footerSecRef = useRef()
  // const references = [firstSecRef, secondSecRef, thirdSecRef, fourthSecRef,fifthSecRef, footerSecRef]
  // const sectionRef = useRef();
  const references = [firstSecRef, secondSecRef, thirdSecRef, fourthSecRef, footerSecRef]
  const [ bgColor, setBgColor ] = useState('#FFFFFF')
  
  const [ dotColor, setDotColor ] = useState(['#F1A039','#FFFFFF','#FFFFFF','#FFFFFF'])
  const config = [7, 100, 0.05]
  const [stickyDot, setStickyDot ] = useState([true,false,false,false ])
  const timeout=150
  const [opacity, setOpacity ] = useState(1) //transparency

  useLayoutEffect(()=>{
    function handleScroll() {
      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight) {
        setOpacity(0)
      }else{
        setOpacity(1)
      }

      // if (window.pageYOffset+window.innerHeight/2>=references[3].current.offsetTop){
      //   setStickyDot([false,false,false,true ])
      //   setDotColor(['#CCCCCC','#CCCCCC','#CCCCCC','#F1A039'])
      //   setBgColor('#CCCCCC')
      // }else if (window.pageYOffset>=references[2].current.offsetTop){
      //   setStickyDot([false,false,true,false ])
      //   setDotColor(['#CCCCCC','#CCCCCC','#F1A039','#CCCCCC'])
      //   setBgColor('#CCCCCC')
      // }else if (window.pageYOffset>=references[1].current.offsetTop){
      //   setStickyDot([false,true,false,false ])
      //   setDotColor(['#CCCCCC','#F1A039','#CCCCCC','#CCCCCC'])
      //   setBgColor('#CCCCCC')
      // }else{
      //   setStickyDot([true,false,false,false ])
      //   setDotColor(['#F1A039','#FFFFFF','#FFFFFF','#FFFFFF'])
      //   setBgColor('#FFFFFF')
      // }
    }
    window.addEventListener('scroll', handleScroll);
    handleScroll()
    return () => window.removeEventListener('scroll', handleScroll);
  },[])

  
  
  // function useQuery() {
  //   return new URLSearchParams(useLocation().search);
  // }
  // let query = useQuery();
  
  // window.onload = function() {
    
  //   if(query.get('jump')==='our_clients') {
  //     setTimeout(()=>{
  //       references[3].current.scrollIntoView({behavior: "smooth"});
  //     },1000)
      
  //   }
  // }
  const handleScrolling = (index)=>{
    const newData = new Array(4).fill(false)
    newData[index] = true
    setStickyDot(newData)
    window.scrollTo({top: references[index].current.offsetTop, behavior: 'smooth'})
  }

  const resetScrolling = () => {
    setStickyDot([true,false,false,false ])
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleClose = () => {
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  useEffect(()=>{
    if ( window.sessionStorage.getItem("firstLoadDone") === null){
      setOpen(true)
      window.sessionStorage.setItem("firstLoadDone",1)
    }else{
      setOpen(false)
    }
  },[])



    return (
      <div style={{scrollBehavior: 'smooth',backgroundColor:'white',overflowX:'hidden'}} >
        {open?null:
        <Hidden smDown>
          {/* <FadeIn> */}
            <div style={{position:'absolute',zIndex:10000, left:'3vw',bottom:'340px'}} >
              <div  style={{position:'fixed',justifyContent:'center',alignContent:'center',height:'340px  '}} >
                <div className={stickyDot[0]?"Dot-Font-active ":"Dot-Font"} style={{color:dotColor[0]}} onClick={()=>handleScrolling(0)}>.</div>
                <div className={stickyDot[1]?"Dot-Font-active ":"Dot-Font"} style={{color:dotColor[1]}} onClick={()=>handleScrolling(1)}>.</div>
                <div className={stickyDot[2]?"Dot-Font-active ":"Dot-Font"} style={{color:dotColor[2]}} onClick={()=>handleScrolling(2)}>.</div>
                <div className={stickyDot[3]?"Dot-Font-active":"Dot-Font"} style={{color:dotColor[3]}} onClick={()=>handleScrolling(3)}>.</div>
                <div  className="line-decoration" style={{borderColor:bgColor,height:'160px',opacity:opacity}}> </div>
              </div>
            </div>
          {/* </FadeIn> */}
        </Hidden>}
        <ReactScrollWheelHandler
          config={config}
          timeout={timeout}
          disableSwipe
          upHandler={(e) => resetScrolling()}
          downHandler={(e) => handleScrolling(1)}
        >
            <NavBar ratio={'0.1'} model={modalOpen}/>
            <FirstSection reference = {firstSecRef} references={references} start={!open} />
        </ReactScrollWheelHandler>
       
          <SecondSection reference = {secondSecRef}/>
        <Container maxWidth="lg" style={{}}>
          <ThirdSection reference = {thirdSecRef} open={modalOpen} setOpen={setModalOpen}/>
          <FifthSection reference = {fourthSecRef} id="our-clients"/>
        </Container>
        {/* <FourthSection reference = {fourthSecRef} />
        <Container maxWidth="lg" style={{}}>
          <FifthSection reference = {fifthSecRef} id="our-clients"/>
        </Container>
        */}
        <Footer reference = {footerSecRef}/>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Lottie 
          options={defaultOptions}
          isClickToPauseDisabled
              height={height}
            width={width}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => setOpen(false),
              },
            ]}
          />
      </Modal>
        
          </div>
    );
  }


