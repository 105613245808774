export const searchProductBIM = {
  title:'BIM',
  short:'BIM',
  searchText:'BIM ' +
      'Request a demo ' +
      'We offer Building Information Modelling (BIM) services - a three dimensional illustration of the entire building lifecycle of the building asset.'+
      'BIM'+
      '3D Modelling',
  description:'We offer Building Information Modelling (BIM) services - a three dimensional illustration of the entire building lifecycle of the building asset.',
  path:'/product/BIM'
}