import './App.css';
import React, { useState } from 'react';
import { Box, Grid,FormControl,Modal,FormGroup,FormControlLabel,Checkbox,Paper, Hidden } from '@material-ui/core';
import { NewTextField } from './textField'
import { SendBTN } from './sendBtn'
import { Form, Field } from 'react-final-form'
import { withStyles } from '@material-ui/core/styles';

const CustCheckbox = withStyles({
    root: {
      color: '#747474',
      '&$checked': {
        color: '#6561B2',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);


export function ModalScreen({open,setOpen}){
    const handleClose = () => {
        setOpen(false)
    }

   
    const [state, setState] = useState({
        Inspection: false,
        DWSS: false,
        BIM: false,
        KMS: false,
        EDMS: false,
        Analyse: false,
        Procurement: false,
        PH: false,
        Sign: false,
      });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        // console.log(userData,state)
      };
     

    const handleSubmit = (values) => {
        console.log(values)
    }

      
    const { Inspection, DWSS,EDMS, BIM, KMS, Analyse,Procurement,PH,Sign} = state;
    const body = (
        <Paper style={{height: '100%',width:'fit-content', overflow: 'auto',backgroundColor:'transparent',marginLeft:'5vw',marginRight:'5vw',marginTop:'3%'}}>
            <Form onSubmit={handleSubmit}
                initialValues={{
                    name:'',
                    email:'',
                    phone:'',
                    company:'',
                    message:'',
                    position:'',
                }}
                render={({ handleSubmit, submitting, valid,pristine ,form,}) => (
                <Box  boxShadow={10} borderRadius='10px'  style={{height:'fit-content',backgroundColor:'white',width:'fit-content',padding:'0px 3% 0px 3% '}}>
                    <Grid container spacing={3}>
                        <Grid item  xs={12}  >
                            <div>
                                <label className="font-montserrat-bold color-orange font-size-rem-xl ">.</label>
                            </div>
                            <label className="font-montserrat-bold color-black letter-spacing-m font-size-header">Request a Demo</label>
                        </Grid>
                        <Grid  item xs={12} >
                            <label className="font-nunito-regular color-lightblack letter-spacing-s font-size-des">Request a demo to get started with Cerebro Products & Services</label>
                        </Grid>
                        <Grid item xs={12} >
                            <Field name={'name'}>
                                {({ input,name}) => {
                                return (
                                    <NewTextField 
                                        label="Name" 
                                        className=""
                                        id="name" 
                                        {...input}
                                        onChange={(e)=>input.onChange(e.target.value)}
                                        required 
                                    />
                                
                                )
                            }}
                                    
                            </Field>

                        </Grid>
                        <Grid item  xs={12} sm={6} >
                            <Field name={'email'}>
                                    {({ input,name}) => {
                                    return (
                                        <NewTextField 
                                            label="Email" 
                                            id="email" 
                                            {...input}
                                            onChange={(e)=>input.onChange(e.target.value)}
                                            required 
                                        />
                                    
                                    )
                                }}
                                        
                            </Field>
                        </Grid>
                        <Grid  item xs={12} sm={6} >
                            <Field name={'phone'}>
                                {({ input,name}) => {
                                return (
                                    <NewTextField 
                                        label="Phone Number" 
                                        id="phone" 
                                        {...input}
                                        onChange={(e)=>input.onChange(e.target.value)}
                                        required 
                                    />
                                
                                )
                            }}
                                    
                            </Field>
                        </Grid>
                               
                        <Grid item   xs={12} sm={6}>
                            <Field name={'company'}>
                                    {({ input,name}) => {
                                    return (
                                        <NewTextField 
                                            label="Company" 
                                            id="company" 
                                            {...input}
                                            onChange={(e)=>input.onChange(e.target.value)}
                                            required 
                                        />
                                    
                                    )
                                }}
                                        
                            </Field>
                        </Grid>
                        <Grid  item   xs={12} sm={6} >
                            <Field name={'position'}>
                                {({ input,name}) => {
                                return (
                                    <NewTextField 
                                        label="Position" 
                                        id="position" 
                                        {...input}
                                        onChange={(e)=>input.onChange(e.target.value)}
                                        required 
                                    />
                                
                                )
                            }}
                            </Field>
                        </Grid>
                        <Grid  item xs={12} >
                            <label className="color-purple font-nunito-bold letter-spacing-m font-size-rem-xs">Interested Products & Services </label>
                        </Grid>
                        <Grid item xs={12}>
                            <Hidden mdDown>
                                <FormControl className="w-100" component="fieldset" style={{marginBottom:'1%'}} >
                                    <FormGroup >
                                        <Grid container   >
                                            <Grid  item  xs={12} sm={3}  >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={Inspection} onChange={handleChange} name="Inspection" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">DWSS - Cerebro Inspection</label>}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} sm={3} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={DWSS} onChange={handleChange} name="DWSS" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">DWSS - Cerebro Diary</label>}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} sm={6} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={EDMS} onChange={handleChange} name="EDMS" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Electronic Document Management System - Cerebro Project</label>}
                                                />
                                            </Grid>
                                        
                                        </Grid>
                                        <Grid container  >
                                            <Grid  item xs={12} sm={3} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={BIM} onChange={handleChange} name="BIM" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">BIM</label>}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} sm={3} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={Analyse} onChange={handleChange} name="Analyse" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Cerebro Analyse</label>}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} sm={6} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={KMS} onChange={handleChange} name="KMS" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Knowledge Management System</label>}
                                                />
                                            </Grid>
                                        
                                        </Grid>
                                        <Grid container   >
                                            <Grid  item   xs={12} sm={3}  >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={Procurement} onChange={handleChange} name="Procurement" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Cerebro Procurement</label>}
                                                />
                                            </Grid>
                                            <Grid  item  xs={12} sm={3}>
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={PH} onChange={handleChange} name="PH" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Photo Registration</label>}
                                                />
                                            </Grid>
                                            <Grid  item  xs={12} sm={6}>
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={Sign} onChange={handleChange} name="Sign" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Signature App</label>}
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </FormControl>
                            </Hidden>
                            <Hidden lgUp>
                                <FormControl className="w-100" component="fieldset" style={{marginBottom:'1%'}} >
                                    <FormGroup >
                                        <Grid container   >
                                            <Grid  item  xs={12} sm={3}  >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={Inspection} onChange={handleChange} name="Inspection" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">DWSS - Cerebro Inspection</label>}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} sm={3} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={DWSS} onChange={handleChange} name="DWSS" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">DWSS - Cerebro Diary</label>}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} sm={6} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={DWSS} onChange={handleChange} name="DWSS" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Electronic Document Management System - Cerebro Project</label>}
                                                />
                                            </Grid>
                                        
                                        </Grid>
                                        <Grid container  >
                                            <Grid  item xs={12} sm={3} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={BIM} onChange={handleChange} name="BIM" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">BIM</label>}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} sm={3} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={Analyse} onChange={handleChange} name="Analyse" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Cerebro Analyse</label>}
                                                />
                                            </Grid>
                                            <Grid  item xs={12} sm={6} >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={KMS} onChange={handleChange} name="KMS" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Knowledge Management System</label>}
                                                />
                                            </Grid>
                                        
                                        </Grid>
                                        <Grid container   >
                                            <Grid  item   xs={12} sm={3}  >
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={Procurement} onChange={handleChange} name="Procurement" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Cerebro Procurement</label>}
                                                />
                                            </Grid>
                                            <Grid  item  xs={12} sm={3}>
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={PH} onChange={handleChange} name="PH" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Photo Registration</label>}
                                                />
                                            </Grid>
                                            <Grid  item  xs={12} sm={6}>
                                                <FormControlLabel
                                                    control={<CustCheckbox checked={Sign} onChange={handleChange} name="Sign" />}
                                                    label={<label className="font-nunito-regular color-black letter-spacing-s font-size-des">Signature App</label>}
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </FormControl>
                            </Hidden>
                            
                            </Grid>
                                <Grid item  xs={12} >
                                    <Field name={'message'}>
                                            {({ input,name}) => {
                                            return (
                                                <NewTextField 
                                                    label="Message" 
                                                    id="message" 
                                                    {...input}
                                                    multiline rows={5} 
                                                    onChange={(e)=>input.onChange(e.target.value)}
                                                    required 
                                                />
                                            
                                            )
                                        }}
                                                
                                        </Field>
                                </Grid>
                            <Grid item style={{marginBottom:'3%'}}>
                                <SendBTN userData={form.getState().values} product={state} mode={true} handleClose={handleClose} />
                            </Grid>
                        </Grid>
                        
                    
        
                </Box>
        )}/>
        </Paper>
       );
    return(
        <Modal
            open={open}
            onBackdropClick={()=>handleClose()}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    )
}