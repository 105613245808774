import './App.css';
import React, { useEffect,useRef } from 'react';
import {  Container,Grid, useMediaQuery } from '@material-ui/core';
import demoVideo from './assets/video/demo.mp4'
import { useWindowSize } from './windowSize'
import "animate.css/animate.min.css";
import anime from 'animejs/lib/anime.es.js';
import Icon from './assets/image/first_page_icon@2x.png'
import { useTheme } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

const targets_list = new Array(21).fill('#header_')
const final_list = targets_list.map((item,i)=>item+i)

export function FirstSection(props){
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  // const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const [width, height] = useWindowSize();
    const animationRef = useRef(null);
    const relatedHeight = height>800||isMobile?0.5:0.2
    const part_a = 50
    
    
    const animationRef2 = useRef(null);
    useEffect(()=>{
      var basicTimeline = anime.timeline({
        autoplay: false 
        
      });
      var basicTimeline2 = anime.timeline({
        autoplay: false
        
      });
      animationRef.current =  basicTimeline
      .add({
        targets: [...final_list,"#header_dot"],
        duration:1,
        translateY: 0,
        easing:'linear',
        opacity:0,
        
      })
      .add({
          targets: "#header_0",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          delay:10
          
        }).add({
          targets: "#header_1",
          duration:part_a,
          translateY:  0,
          easing:'linear',
          opacity:1
          
        }).add({
          targets: "#header_2",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_3",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_4",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_5",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_6",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_7",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_8",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_9",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_10",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_11",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_12",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_13",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_14",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_15",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_16",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
        }).add({
          targets: "#header_17",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_18",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_19",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_20",
          duration:part_a,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
        }).add({
          targets: "#header_dot",
          duration:part_a,
          translateY:  0,
          zIndex:50,
          easing:'linear',
          opacity:1
          
        })

        animationRef2.current =  basicTimeline2
      .add({
        targets:[".des_0","#citf","#citf_des_0","#citf_des_1"],
        duration:1,
        translateY: 100,
        easing:'linear',
        opacity:0,
        
      }).add({
        targets: ".des_0",
        duration:240,
        translateY:  0,
        easing:'linear',
        opacity:1,
        
      }).add({
        targets: "#citf",
        duration:270,
        translateY:  0,
        easing:'linear',
        opacity:1,
        
      }).add({
        targets: "#citf_des_0",
        duration:270,
        translateY:  0,
        easing:'linear',
        opacity:1,
        
      })
      .add({
        targets: "#citf_des_1",
        duration:270,
        translateY:  0,
        easing:'linear',
        opacity:1,
        
      })
    },[])
    
    useEffect(()=>{
      if(props.start){
          animationRef.current.restart()
          animationRef2.current.restart()
           
      }
    },[props.start])

    return(
        <div className="" ref={props.reference} style={{backgroundColor: '#12043F',width:width}}>
          <div className="videoContainer">
            <div  className="Video-Cover" style={{zIndex:10,height:height*relatedHeight}}> 
            </div>
            <div className="Video-Cover-2" style={{zIndex:10,height:height*(1-relatedHeight),top:height*relatedHeight}}>
            <Container maxWidth="lg" >
            {/* <Hidden xsDown> */}
            {matches?
              <Grid container className="Video-Des"  style={{position:'relative',zIndex:50,top:'3rem',left:'4vw'}} spacing={7}>
                  <Grid item xs={12}>
                    <Grid container direction="column" >
                      <Grid item  xs={12} >
                        {'Initiate'.split("").map((word,i)=>{
                          const id = 'header_'+i
                          return(
                          <label id={id} key={id} style={{zIndex:50}} className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xl banner-text-shadow" >
                          {word}
                        </label>
                        )})}
                      </Grid>
                      <Grid item  xs={12} >
                        {'Possibilities'.split("").map((word,i)=>{
                          const index = i+8
                          const id = 'header_'+index
                          return(
                          <label id={id} key={id} style={{zIndex:50}} className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xl banner-text-shadow" >
                          {word}
                        </label>
                        )})}
                        
                        <label style={{marginLeft:'1rem'}} id="header_dot" className="font-montserrat-bold color-orange font-size-rem-xl banner-text-shadow" >
                          .
                        </label>
                      </Grid>
                    
                    </Grid>
                  </Grid>
                  <Grid item  xs={10} sm={6}>
                    <label  className="font-nunito-regular color-white letter-spacing-s font-size-rem-xs des_0 banner-text-shadow">
                      We are your trusted software engineering partner, with the leverage of smart innovations and cloud technologies, empowering businesses to achieve more in the digital acceleration.  
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container  direction="row" alignItems="flex-end" >
                      <Grid item xs={2}>
                          <img id="citf" className="w-80" src={Icon} alt="" />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container direction="column"  >
                          <label id="citf_des_0" className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs banner-text-shadow">
                            Cerebro Inspection is pre-approved by
                          </label>
                          <label id="citf_des_1" className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs banner-text-shadow">
                          the Construction Innovation & Technology Fund (CITF)
                          </label>
                        </Grid>
                          
                      </Grid>
                    
                    </Grid>
                  </Grid>
                </Grid>:
                <Grid container className="Video-Des"  style={{position:'relative',left:'2vw',zIndex:50,top:'-12rem'}} spacing={3}>
                    <Grid item xs={12}>
                      <Grid container direction="column" >
                        <Grid item  xs={12} >
                          {'Initiate'.split("").map((word,i)=>{
                            const id = 'header_'+i
                            return(
                            <label id={id} key={id} style={{zIndex:50}} className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xl banner-text-shadow" >
                            {word}
                          </label>
                          )})}
                        </Grid>
                        <Grid item  xs={12} >
                          {'Possibilities'.split("").map((word,i)=>{
                            const index = i+8
                            const id = 'header_'+index
                            return(
                            <label id={id} key={id} style={{zIndex:50}} className="font-montserrat-bold color-white letter-spacing-m font-size-rem-xl banner-text-shadow" >
                            {word}
                          </label>
                          )})}
                          
                          <label style={{marginLeft:'1rem'}} id="header_dot" className="font-montserrat-bold color-orange font-size-rem-xl banner-text-shadow" >
                            .
                          </label>
                        </Grid>
                      
                      </Grid>
                    </Grid>
                    <Grid item  xs={12} sm={6} style={{marginBottom:"24px"}}>
                      <label  className="font-nunito-regular color-white letter-spacing-s font-size-rem-xs des_0 banner-text-shadow">
                        We are your trusted software engineering partner, with the leverage of smart innovations and cloud technologies, empowering businesses to achieve more in the digital acceleration.  
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container  direction="row" alignItems="flex-end" spacing={3}>
                        <Grid item xs={12}>
                            <img className="w-60" id="citf" src={Icon} alt=""/>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container direction="column"  >
                            <label id="citf_des_0" className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs banner-text-shadow">
                              Cerebro Inspection is pre-approved by
                            </label>
                            <label id="citf_des_1" className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs banner-text-shadow">
                            the Construction Innovation & Technology Fund (CITF)
                            </label>
                          </Grid>
                            
                        </Grid>
                      
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Container>
            </div>
            <video style={{width:width,height:height}}   className="demoVideo" loop autoPlay muted playsInline>
              <source src={demoVideo} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
        
      </div>

    )
}