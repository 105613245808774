import './App.css';
import React ,{ useEffect, useState } from 'react';
import { Grid, Hidden,useMediaQuery,Container} from '@material-ui/core';
import { Footer } from './footer'
import background from "./assets/image/contact.png";
import { useTheme } from '@material-ui/core/styles';
import { NewTextField } from './textField'
import { NavBar } from './navbar'
import { SendBTN } from './sendBtn'
import { Form, Field } from 'react-final-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWindowSize } from './windowSize'
import anime from 'animejs/lib/anime.es.js';
import { isFirefox } from 'react-device-detect';


export function ContactUs(){
    const [width, height] = useWindowSize();
    const [animation, setAnimation] = useState(false)
    const [isHovering, setIsHovering] = useState(false);
    const [ count , setCount ] = useState(0)
    const animationRef = React.useRef(null);
   const animationRef2 = React.useRef(null);
   const handleMouseOver = () => {
     setCount(count+1)
    //  if(count % 20 === 0 && count!==0){
    //   var win = window.open('https://api.whatsapp.com/send?phone=85266882764&text=%E9%BB%9E%E6%93%8A%E8%88%87%E6%88%91%E5%B1%95%E9%96%8B%E6%BF%80%E6%83%85%E5%B0%8D%E8%A9%B1', '_blank');
    //   win.focus();
    //  }
  
    // setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

   useEffect(()=>{
    var basicTimeline = anime.timeline({
        autoplay: true
      });
      var basicTimeline2 = anime.timeline({
        autoplay: true
      });
    if(animation){
      animationRef.current =  basicTimeline
      .add({
        targets: ["#shadow-box","#name-field","#email-field","#phone-field","#company-field","#position-field","#message-field","#send-btn"],
        duration:1,
        translateY: 100,
        easing:'linear',
        opacity:0,
      })
      .add({
          targets: "#shadow-box",
          duration:300,
          translateY: 0,
          easing:'linear',
          opacity:1,
          
          
        }).add({
            targets: "#name-field",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1,
          }).add({
            targets: "#email-field",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1,
          }).add({
            targets: "#phone-field",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#company-field",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#position-field",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#message-field",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#send-btn",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          })
          
          
          animationRef2.current =  basicTimeline2
      .add({
        targets: ["#contact","#general","#phone","#phone-content","#email","#email-content","#office","#address","#address-content"],
        duration:1,
        translateY: 100,
        easing:'linear',
        opacity:0,
      })
      .add({
            targets: "#contact",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1,
            delay:300
            
          }).add({
            targets: "#general",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#email",
            duration:200,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#email-content",
            duration:200,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#phone",
            duration:200,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#phone-content",
            duration:200,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#office",
            duration:300,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#address",
            duration:200,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          }).add({
            targets: "#address-content",
            duration:200,
            translateY: 0,
            easing:'linear',
            opacity:1
            
          })
      }
},[animation])

useEffect(()=>{
  function animationStart() {
      setAnimation(true)
      
    }
  window.addEventListener('load', animationStart);
  animationStart()
  return () => window.removeEventListener('load', animationStart);
})

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const desktop = useMediaQuery(theme.breakpoints.up("lg"));
    // const tablet = useMediaQuery(theme.breakpoints.up("sm"));
    const mobile = useMediaQuery(theme.breakpoints.up("xs"));
    const handleSubmit = (values) => {
        
    }

    const handleClose = () => {
        window.location.reload();
       
    }
    return(
        <div className="FirstElement">
            <NavBar />
            <div className="videoContainer">
            <div  className="Video-Cover" style={{zIndex:10,height:height>800?height*0.8:'640px'}}> 
              <Container  maxWidth="lg" style={{}}>
                <div id="shadow-box" className="rounded card-box" style={{position:'relative',zIndex:20,backgroundColor:'rgba(2,5,69,0.4)',left:matches?'0px':'4vw',width:matches?'100%':'90%',top:height>900?height*0.2:'70px',height:matches||mobile?isFirefox?'730px':'fit-content':height*0.88,padding:desktop?'3rem':'3rem 1rem 0rem 1rem'}}>
                <Form onSubmit={event=>{handleSubmit(event)}}
                        initialValues={{
                            name:'',
                            email:'',
                            phone:'',
                            company:'',
                            message:'',
                            position:'',
                        }}
                        render={({ handleSubmit, submitting, valid,pristine ,form,reset}) => (
                        <Grid container  style={{height:'fit-content'}} spacing={4}>
                            <Grid item xs={12} sm={6}  style={{}} >
                                <Grid container spacing={3} direction="column" >
                                    <Grid id='name-field' style={{paddingRight:desktop?'3rem':'1rem'}}  item >
                                        <Field name={'name'}>
                                                {({ input,name}) => {
                                                return (
                                                    <NewTextField 
                                                        label="Name" 
                                                        inputcolor="white"
                                                        border="white"
                                                        labelcolor="white"
                                                        
                                                        id="name" 
                                                        {...input}
                                                        onChange={(e)=>input.onChange(e.target.value)}
                                                        required 
                                                    />
                                                
                                                )
                                            }}
                                                    
                                        </Field>
                                    </Grid>
                                    <Grid id='email-field' style={{paddingRight:desktop?'3rem':'1rem'}}  item >
                                        <Field name={'email'}>
                                                {({ input,name}) => {
                                                return (
                                                    <NewTextField 
                                                        label="Email" 
                                                        inputcolor="white"
                                                        border="white"
                                                        labelcolor="white"
                                                        id="email" 
                                                        {...input}
                                                        onChange={(e)=>input.onChange(e.target.value)}
                                                        required 
                                                    />
                                                
                                                )
                                            }}
                                                    
                                        </Field>
                                    </Grid>
                                    <Grid id='phone-field' style={{paddingRight:desktop?'3rem':'1rem'}}  item >
                                        <Field name={'phone'}>
                                                {({ input,name}) => {
                                                return (
                                                    <NewTextField 
                                                        label="Phone" 
                                                        inputcolor="white"
                                                        border="white"
                                                        labelcolor="white"
                                                        id="phone" 
                                                        {...input}
                                                        onChange={(e)=>input.onChange(e.target.value)}
                                                        required 
                                                    />
                                                
                                                )
                                            }}
                                                    
                                        </Field>
                                    </Grid>
                                    <Grid id='company-field' style={{paddingRight:desktop?'3rem':'1rem'}}  item   >
                                        <Field name={'company'}>
                                                {({ input,name}) => {
                                                return (
                                                    <NewTextField 
                                                        label="Company" 
                                                        inputcolor="white"
                                                        border="white"
                                                        labelcolor="white"
                                                        id="company" 
                                                        {...input}
                                                        onChange={(e)=>input.onChange(e.target.value)}
                                                        required 
                                                    />
                                                
                                                )
                                            }}
                                                    
                                        </Field>
                                    </Grid>
                                    <Grid id='position-field' style={{paddingRight:desktop?'3rem':'1rem'}}  item  > 
                                        <Field name={'position'}>
                                                {({ input,name}) => {
                                                return (
                                                    <NewTextField 
                                                        label="Position" 
                                                        inputcolor="white"
                                                        border="white"
                                                        labelcolor="white"
                                                        id="position" 
                                                        {...input}
                                                        onChange={(e)=>input.onChange(e.target.value)}
                                                        required 
                                                    />
                                                
                                                )
                                            }}
                                                    
                                        </Field>
                                    </Grid>
                                    <Grid id='message-field' style={{paddingRight:desktop?'3rem':'1rem'}}  item   >
                                        <Field name={'message'}>
                                            {({ input,name}) => {
                                            return (
                                                <NewTextField 
                                                    label="Message" 
                                                    id="message" 
                                                    inputcolor="white"
                                                    border="white"
                                                    labelcolor="white"
                                                    {...input}
                                                    multiline rows={5} 
                                                    onChange={(e)=>input.onChange(e.target.value)}
                                                    required 
                                                />
                                            
                                            )
                                        }}
                                                
                                        </Field>
                                        
                                    </Grid>
                                    <Hidden xsDown>
                                        <Grid className="shadow-" id='send-btn' style={{paddingRight:desktop?'3rem':'1rem'}}  item xs={6}  >
                                            <SendBTN userData={form.getState().values} handleClose={handleClose}/>
                                        </Grid>
                                    
                                    </Hidden>
                                    <Hidden smUp>
                                        <Grid id='send-btn'   item xs={6}  >
                                            <SendBTN userData={form.getState().values} mode={true} handleClose={handleClose}/>
                                        </Grid>
                                    
                                    </Hidden>
                                   
                                </Grid>
                               
                            </Grid>
                            <Hidden xsDown>
                                <Grid  item xs={12} sm={6} style={{borderLeft:'1px solid white',paddingLeft:matches?'3rem':'1rem'}}>
                                    <Grid id='contact' className=""  container style={{marginBottom:"2rem"}} alignItems="center">
                                        <label  className="font-montserrat-bold color-white letter-spacing-m font-size-rem-md">Contact Us</label>
                                        <label style={{marginLeft:'5px'}} className="font-montserrat-bold color-orange font-size-rem-lg">.</label>
                                    </Grid>
                                    <label id='general'  style={{}} className="font-montserrat-bold color-orange letter-spacing-m font-size-rem-sm margin-header-content">General Inquiries</label>
                                    <Grid container style={{marginBottom:'2vh'}}>
                                        <FontAwesomeIcon   id='email' size="xs" color="white" icon={["fas", "envelope"]} />
                                        <label style={{paddingLeft:matches?'3rem':'1rem'}} id='email-content' className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs">info@cerebrohk.com</label>
                                    </Grid>
                                    <Grid  container style={{}}>
                                        <FontAwesomeIcon onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} id='phone' size="xs" color="white"  icon={["fas", "phone-alt"]}/>
                                        <label style={{paddingLeft:matches?'3rem':'1rem'}} id='phone-content' className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs margin-header-content">852 9459 9679</label>
                                    </Grid>
                                    <label id='office'  style={{}} className="font-montserrat-bold color-orange letter-spacing-m font-size-rem-sm margin-header-content">Office</label>
                                    <Grid container>
                                        <Grid id='address' style={{}}  item xs={1}>
                                            <FontAwesomeIcon size="xs" color="white"  icon={["fas", "map-marker-alt"]} />
                                        </Grid>
                                        <Grid id='address-content' style={{paddingLeft:matches?'2rem':'1rem'}}  item xs={10}>
                                            <label className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs" >Unit F, 11/F, MG Tower, 133 Hoi Bun Road, <br></br>Kwun Tong, Kowloon, Hong Kong</label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                            
                        )}/>
                </div>
                </Container>
            </div>

            <div className="Video-Cover-2" style={{zIndex:5,height:height>800?height*0.2:'170px',top:height>800?height*0.8:'640px'}}>
              <Grid container className="Video-Des" alignItems="center"  style={{zIndex:10,position:'absolute',left:'calc(24px + 5vw)'}}>
                <Grid item xs={12}>
                  <Grid container direction="column" spacing={6}>
                    <Grid item  xs={10} sm={6}>
                      {/* <label className="font-nunito-regular color-white letter-spacing-s font-size-rem-xs">
                        We are your trusted software engineering partner, with the leverage of smart innovations and cloud technologies, empowering businesses to achieve more in the digital acceleration.  
                      </label> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <img className="demoVideo" src={background} style={{width:width,height:height,filter:'blur(3px)',minHeight:'800px'}} alt=""/>
      </div>
        <Hidden smUp>
            <Grid container spacing={2} style={{height:'fit-content',backgroundColor:'#12043F'}}>
            <Grid item xs={12} sm={6} style={{padding:'10% 10%'}}>
                <div>
                    <label className="font-montserrat-bold color-orange font-size-rem-lg">.</label>
                </div>
                <div style={{marginBottom:'10%'}}>
                    <label  className="font-montserrat-bold color-white letter-spacing-m font-size-rem-md">Contact Us</label>
                </div>
                <label style={{marginBottom:'5%'}} className="font-montserrat-bold color-orange letter-spacing-m font-size-rem-sm">General Inquiries</label>
                <Grid container  style={{marginBottom:'5%'}}>
                    <Grid item xs={2}>
                        <FontAwesomeIcon  color="white" icon={["fas", "envelope"]} />
                    </Grid>
                    <Grid item xs={10}>
                        <label className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs">info@cerebrohk.com</label>
                    </Grid>
                </Grid>
                {/* <Grid container style={{marginBottom:'10%'}}>
                    <Grid item xs={2}>
                        <FontAwesomeIcon  color="white" icon={["fas", "phone-alt"]} />
                    </Grid>
                    <Grid item xs={10}>
                    <label className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs">852 2272 3774</label>
                    </Grid>
                </Grid> */}
                <label style={{marginBottom:'5%'}} className="font-montserrat-bold color-orange letter-spacing-m font-size-rem-sm">Office</label>
                <Grid container>
                    <Grid item xs={2}>
                        <FontAwesomeIcon  color="white" icon={["fas", "map-marker-alt"]} />
                    </Grid>
                    <Grid item xs={10}>
                        <label className="font-nunito-bold color-white letter-spacing-s font-size-rem-xs" >Unit F, 11/F, MG Tower, 133 Hoi Bun Road, <br></br>Kwun Tong, Kowloon, Hong Kong</label>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Hidden>
        {/* <Hidden only={['xs','xl','lg']}>
            <Box container item style={{height:matches?'30vh':'50vh',backgroundColor:'#12043F'}}></Box>
        </Hidden> */}
        <Footer />
    </div>
    )
}