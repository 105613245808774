import './App.css';
import React, { useEffect, useState } from 'react';
import { Grid, Button, Chip, Hidden, Card, CardContent, CardHeader,useMediaQuery } from '@material-ui/core';
import Inspection from './assets/image/Inspection.png'
import Diary from './assets/image/Diary.png'
import EDMS from './assets/image/EDMS.png'
import BIM from './assets/image/BIM.png'
import KMS from './assets/image/KMS.png'
import Analyse from './assets/image/Analyse.png'
import Procurement from './assets/image/Procurement.png'
import PhotoReg from './assets/image/PhotoReg.png'
import Signature from './assets/image/Signature.png'
import Inspection_i from './assets/image/inspection_s_icon.png'
import Diary_i from './assets/image/diary_s_icon.png'
import EDMS_i from './assets/image/EDMS_s_icon.png'
import BIM_i from './assets/image/BIM_s_icon.png'
import KMS_i from './assets/image/KMS_s_icon.png'
import Analyse_i from './assets/image/analyse_s_icon.png'
import Procurement_i from './assets/image/procurement_s_icon.png'
import PhotoReg_i from './assets/image/pr_s_icon.png'
import Signature_i from './assets/image/sign_s_icon.png'
import { withStyles, useTheme } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import { ModalScreen } from './modal'
import '../src/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from "react-router-dom";
import {
    isBrowser,
    isChrome
  } from "react-device-detect";
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

const data = [
    {
        img:Inspection,
        icon:Inspection_i,
        bgColor:'#28626D',
        className:'Inspection',
        Mobile:true,
        Desktop:true,
        type:'Digital Works Supervision System (DWSS)',
        name:'Cerebro Inspection',
        des:'All in one digital platform to achieve paperless and automated inspection.',
        tags:['Inspection','Site Management','DWSS','Safety','Survey','Quality','Environmental','Mobile Application','Web Application'],
    },
    {
        img:Diary,
        icon:Diary_i,
        bgColor:'#5CD4EF',
        className:'Diary',
        Mobile:false,
        Desktop:true,
        type:'Digital Works Supervision System (DWSS)',
        name:'Cerebro Diary',
        des:'Digital Site Diary platform to record daily site activities, with no hassle.',
        tags:['Site Diary','Site Management','DWSS','Safety','Quality','Site Activities','Labour Management','Web Application'],
    },
    {
        img:EDMS,
        icon:EDMS_i,
        bgColor:'#3A3A3A',
        className:'EDMS',
        Mobile:false,
        Desktop:true,
        type:'Electronic Document Management System',
        name:'Cerebro Project',
        des:'Integrated platform to secure, organise and update company documents and drawings.',
        tags:['EDMS','Project Management','Document Management','Web Application'],
    },
    {
        img:BIM,
        icon:BIM_i,
        bgColor:'#221E57',
        className:'BIM',
        Mobile:false,
        Desktop:true,
        type:'',
        name:'BIM',
        des:'3D illustration of full building lifecycle for building assets.',
        tags:['BIM','3D Modelling'],
    },
    {
        img:KMS,
        icon:KMS_i,
        bgColor:'#30C2CA',
        className:'KMS',
        Mobile:false,
        Desktop:true,
        type:'',
        name:'Knowledge Management System',
        des:'Centralized knowledge sharing platform for all project information and know-hows.',
        tags:['Project Management','Knowledge Management','Web Application','Knowledge Sharing'],
    },
    {
        img:Analyse,
        icon:Analyse_i,
        bgColor:'#1D1429',
        className:'Analyse',
        Mobile:false,
        Desktop:true,
        type:'',
        name:'Cerebro Analyse',
        des:'Electronic construction budgeting and estimation software.',
        tags:['Project Management','Budget','Estimation','Cost Management','Web Application'],
    },
    {
        img:Procurement,
        icon:Procurement_i,
        bgColor:'#1E3957',
        className:'Procurement',
        Mobile:true,
        Desktop:true,
        type:'',
        name:'Cerebro Procurement',
        des:'Full-fledged digital platform to manage vendor and material sourcing.',
        tags:['Procurement','Project Management','Sourcing','Material','Site Management','Subcontractor','Quantity Surveying','Web Application'],
    },
    {
        img:PhotoReg,
        icon:PhotoReg_i,
        bgColor:'#3D3D3D',
        className:'PhotoReg',
        Mobile:true,
        Desktop:false,
        type:'Cerebro Project',
        name:'Photo Registration',
        des:'Affiliated cloud-based photo upload and register mobile application.',
        tags:['Project Management','Photo Storage','Site Management','Mobile Application'],
    },
    {
        img:Signature,
        icon:Signature_i,
        bgColor:'#2B2969',
        className:'Signature',
        Mobile:true,
        Desktop:true,
        type:'',
        name:'Signature App',
        des:'United signature application for all Cerebro platforms.',
        tags:['Site Management','Project Management','E-signature','Mobile Application'],
    }
]



const CssButton = withStyles({
    root:{
        '&:hover':{
            backgroundColor:"transparent"
        },
        width:'100%'
        
    },
    label:{
        height:'100%',
        display:'unset',
    }
})(Button)


export function ThirdSection(props){
    const theme = useTheme()
    const open = props.open
    const setOpen = props.setOpen
    const tablet = useMediaQuery(theme.breakpoints.up("sm"));
    const mobile = useMediaQuery(theme.breakpoints.up("xs"));
    const desktop = useMediaQuery(theme.breakpoints.up("lg"));
    const history = useHistory()
    const [saveTop, setSaveTop ] = useState(0)
    const [ items, setItems ] = useState(new Array(data.length).fill(false))
    



    const handleClick = (index,state) => {
        if (isBrowser){
            const newData = new Array(data.length).fill(false)
            newData[index] = state
            setItems(newData)
           
        }
       
      
  }

    const ColorButton = withStyles((theme) => ({
        root: {
          color: theme.palette.getContrastText(purple[500]),
          backgroundColor: '#6561B2',
          height:'100%',
          width:'300px',
          '&:hover': {
            backgroundColor: purple[700],
          },
        },
      }))(Button);
    
    useEffect(()=>{
        if(open){
            document.getElementById('root').style.position = 'fixed'
            document.getElementById('root').style.top = `-${saveTop}px`

        }else{
            document.getElementById('root').style.removeProperty('position')
            document.getElementById('root').style.removeProperty('top')
            document.documentElement.scrollTop = document.body.scrollTop = saveTop

        }
    },[open])

    const handleOpen = () => {
        setOpen(true)
        setSaveTop(document.body.scrollTop || document.documentElement.scrollTop || 0)
    }

 
      
    // const eachHeight = (index) => {
    //     if (isChrome){
    //         const height = '100%'
    //         return height
    //     }else{
    //         if (desktop){
    //             if(index<3){
    //                 const height = '460px'
    //                 return height
    //             }else if ( 3<=index && index<6){
    //                 const height = '400px'
    //                 return height
    //             }else{
    //                 const height = '440px'
    //                 return height

    //             }
    //         }else
    //         if (tablet){
    //             if(index<2){
    //                 const height = '37vh'
    //                 return height
    //             }else if ( 2<=index && index<4){
    //                 const height = '36vh'
    //                 return height
    //             }else if ( 4<=index && index<6){
    //                 const height = '35vh'
    //                 return height
    //             }else if ( 6<=index && index<8){
    //                 const height = '37vh'
    //                 return height
    //             }else{
    //                 const height = '34vh'
    //                 return height

    //             }
               
    //         }else if (mobile){
    //             const height = '300px'
    //             return height
    //         }
    //     }
    // }
 
    
    return(
        <div className="Content-Container" ref={props.reference} style={{padding:'0 4vw'}} >
            <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                <Grid container className="margin-header-content" style={{}}>
                        <Grid item xs={12}>
                            <label className="font-montserrat-bold color-purple font-size-rem-xl margin-dot-top">.</label>
                        </Grid> 
                        <Grid item  xs={12} sm={6} >
                            <label  className="font-montserrat-bold color-black letter-spacing-m font-size-header ">Products & Services</label>
                        </Grid>
                        <Hidden xsDown>
                                <Grid item  xs={12} sm={6}>
                                    <Grid container justify="flex-end">
                                        <Button className="btn-transition-none btn-gradient shadow-none " onClick={()=>handleOpen()} variant="contained" size='medium'  color="primary" style={{width:'250px',borderRadius:5,backgroundColor:'#6561B2',marginLeft:'1%'}}>
                                            <label className="font-montserrat-bold letter-spacing-s button-size-labelbutton-padding-h" style={{textTransform:'initial'}}>Request a Demo</label>
                                        </Button>
                                    </Grid>
                                </Grid>
                        </Hidden>
                        <Hidden smUp>
                                <Grid item  xs={12}  >
                                    <Grid container justify="center" style={{margin:'2vh 0% calc(2vh - 8px) 0%'}}>
                                        <ColorButton onClick={()=>handleOpen()} className="Request-Btn btn-transition-none btn-gradient shadow-none" variant="contained" color="primary" size="medium">
                                            <label className="font-montserrat-bold letter-spacing-s button-size-label button-padding-h w-100"style={{textTransform:'initial'}} >Request a Demo</label>
                                        </ColorButton>
                                    </Grid>
                                </Grid>
                        </Hidden>
                </Grid>
            </ScrollAnimation>
            <Grid className="margin-thirdpart" container>
                   {data.map((e, i)=> (
                       <Grid item xs={12} sm={6} lg={4} key={i} 
                            onMouseEnter={()=>handleClick(i,true)} onMouseLeave={()=>handleClick(i,false)}>    
                           <CssButton className="text-left h-100"  onClick={()=>history.push("/product/"+e.className)}>
                                <ScrollAnimation animateIn='animate__fadeInUp h-100'   animateOnce={true} animatePreScroll={false}>
                                    <Card style={{}} className="card-box h-100 rounded-bottom-0 ">
                                        <Hidden smUp>
                                            <Grid container justify="flex-end" style={{position:'absolute',right:'20px',top:'10px'}}>
                                                <div>{e.Desktop?<FontAwesomeIcon color="#020545" icon={["fas", "desktop"]} />:null}</div>
                                                <div style={{paddingLeft:e.Mobile?'20px':'0px'}}>{e.Mobile?<FontAwesomeIcon color="#020545" icon={["fas", "mobile-alt"]} />:null}</div>
                                            </Grid>
                                            <CardHeader
                                                title={<div className="text-center"><img src={e.icon} style={{width:'30%'}} alt=""/></div>}
                                            />
                                        </Hidden>
                                        <Hidden xsDown>
                                            <Grid className={"Product-Icon"+i} container style={{position:'absolute',right:'10px',top:'10px',zIndex:10}} justify="flex-end">
                                                <FontAwesomeIcon className={"Product-Icon"+i} style={{opacity:items[i]?1:0}} color="#FFFFFF" icon={["fas", "arrow-right"]} />
                                            </Grid>
                                            <div className="text-center rounded-top" style={{backgroundColor: e.bgColor}}>
                                                <img className="h-100 w-100" src={e.img} alt=""/>
                                            </div>
                                            
                                            
                                        </Hidden>
                                        <CardContent>
                                            <Hidden xsDown>
                                                <Grid container justify="flex-end">
                                                    
                                                    <div>{e.Desktop?<FontAwesomeIcon color="#020545" icon={["fas", "desktop"]} />:null}</div>
                                                    <div style={{paddingLeft:e.Mobile?'20px':'0px'}}>{e.Mobile?<FontAwesomeIcon color="#020545" icon={["fas", "mobile-alt"]} />:null}</div>
                                                </Grid>
                                            </Hidden>
                                            <div className="font-montserrat-bold color-blue letter-spacing-s card-data-font-size">{e.type}</div>
                                            <div className="font-montserrat-bold color-blue letter-spacing-s card-data-header-font-size margin-card-info" style={{textTransform:'initial'}}>{e.name}</div>
                                            <div className="font-nunito-regular color-lightblack letter-spacing-s card-data-font-size margin-card-info" style={{textTransform:'initial'}}>{e.des}</div>
                                            <Hidden xsDown>
                                                <div className="font-nunito-regular color-lightblack letter-spacing-s card-data-font-size margin-card-info" style={{textTransform:'initial'}}>Tags:</div>
                                                {e.tags.map((tag,i)=>(
                                                    <Chip 
                                                        className="chip-margin" 
                                                        key={tag+i} 
                                                        style={{margin:'1%'}}
                                                        onClick={(event)=>{
                                                            event.stopPropagation()
                                                            history.push("/search?words="+tag)
                                                            window.location.reload()
                                                            }}
                                                        label={
                                                            <label 
                                                                className="font-nunito-regular color-black letter-spacing-s" 
                                                                style={{textTransform:'initial',fontSize:'0.7rem'}}
                                                            >
                                                                {tag}
                                                            </label>
                                                            } 
                                                        variant="outlined" 
                                                    />
                                                ))}
                                            </Hidden>
                                        </CardContent>
                                    </Card>
                                </ScrollAnimation>
                           </CssButton>
                        </Grid>
                   ))}
            </Grid>
            
            <ModalScreen open={open} setOpen={setOpen}/>
           
           
        </div>
    )
}