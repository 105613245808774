export const searchContactUs = {
  title:'Contact Us',
  short:'Contact Us',
  searchText:'Contact Us ' +
      'General Inquiries ' +
      'info@cerebrohk.com ' +
      '852 2272 3774 ' +
      'Office 20/F, Benson Tower, 74 Hung To Road, Kwun Tong, Kowloon, Hong Kong',
  description: 'info@cerebrohk.com, 20/F, Benson Tower, 74 Hung To Road, Kwun Tong, Kowloon, Hong Kong',
  path:'/product/contact'
}