import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Grid, Menu, MenuItem, Hidden, List, ListItem, ListItemText, Collapse, Paper, TextField, Button, Container } from '@material-ui/core';
import iconHeader from './assets/image/icon_des.png'
import './App.css'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import anime from 'animejs/lib/anime.es.js';
import { ModalScreen } from './modal'
import { Autocomplete } from "@material-ui/lab";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HashLink, } from 'react-router-hash-link';
import SimplePopover from './popover'
import { productName, productRoute } from './product_json'


const StyledMenu = withStyles({

})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));


const CssTextFieldNew = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: 'orange !important',
    },
    '&  .MuiInput-underline:before': {
      borderBottomColor: 'white !important',
    },
    "&.MuiInput-underline:hover:before": {
      borderBottom: "2px solid white !important"
    }


  },
})(TextField);



const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: 'white',
    height: 'fit-content',
    padding: '20px 50px 20px 10px',
    width: 'fit-content'
  },

}))(MenuItem);

export function NavBar(props) {
  const [searchAnchorEl, setSearchAnchorEl] = React.useState(null);



  const useStyles = makeStyles((theme) => ({
    menu: {
      "& .MuiPaper-root": {
        backgroundColor: "#12043F",
        width: 'fit-content'


      },
      "& .MuiList-padding": {
        padding: '5%'
      }
    },
    webSearchText: {
      "color": '#FFF'
    },
    clearIndicator: {
      color: "#FFF",
    },
    popupIndicator: {
      color: "#FFF",
    },
    searchPaper: {
      backgroundColor: '#020545',
    },
    searchListBox: {
      color: 'white'
    },
  }));
  const classes = useStyles();
  const ratio = props.ratio ? props.ratio : 0.1
  const history = useHistory();
  // if (history.scrollRestoration) {
  //   history.scrollRestoration = 'manual';
  // }
  const [open, setOpen] = useState(false)
  const [listOpen, setListOpen] = useState(false)


  const startAnimation = () => {
    anime({
      targets: '#nav-icon1',
      translateY: '0.5vh',
      opacity: 0
    });
    anime({
      targets: '#nav-icon4',
      translateY: '-0.5vh',
      opacity: 0
    });
    anime({
      targets: '#nav-icon2',
      rotate: '45',
    });
    anime({
      targets: '#nav-icon3',
      rotate: '-45',
    });
  }

  const closeAnimation = () => {
    anime({
      targets: '#nav-icon6',
      rotate: '0',
    });
    anime({
      targets: '#nav-icon7',
      rotate: '0',
    });
    anime({
      targets: '#nav-icon5',
      translateY: '-0.5vh',
      opacity: 1
    });
    anime({
      targets: '#nav-icon8',
      translateY: '0.5vh',
      opacity: 1
    });


  }
  const handleMenu = () => {
    if (!open) {
      startAnimation()
      setTimeout(function () {
        setOpen(true)
      }, 300);

    } else {
      closeAnimation()
      setTimeout(function () {
        setOpen(false)
      }, 300);
    }



  }

  const MdMeun = () => (
    <div onClick={() => handleMenu()} id="nav-icon">
      <span id="nav-icon1"></span>
      <span id="nav-icon2"></span>
      <span id="nav-icon3"></span>
      <span id="nav-icon4"></span>
    </div>
  )

  const MdClose = () => (
    <div onClick={() => handleMenu()} id="nav-closeIcon">
      <span id="nav-icon5"></span>
      <span id="nav-icon6"></span>
      <span id="nav-icon7"></span>
      <span id="nav-icon8"></span>
    </div>
  )
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= window.innerHeight * ratio) {
      setColorchange(true);
    }
    else {
      if (anchorEl) {
        setColorchange(true);
      } else {
        setColorchange(false);
      }

    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (event) => {
    setColorchange(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setColorchange(false)
    setAnchorEl(null);
  };

  const handleSelected = (path) => {
    history.push("/product/" + path)
    setAnchorEl(null);
  };

  const handlePush = (path) => {
    history.push("/" + path)
    setOpen(false);
    setListOpen(false)
    setRequestOpen(false)
  }

  const [requestOpen, setRequestOpen] = useState(false)
  const hadnleRequest = () => {
    setRequestOpen(open)
    setOpen(false)
  }


  return (
    <React.Fragment>

      <AppBar elevation={0} position="fixed" style={{ background: colorChange ? '#12043F' : open ? '#12043F' : props.model ? '#12043F' : 'none' }}>
        <Container maxWidth="lg">
          <Toolbar style={{ zIndex: 10 }}>
            <Box className="App-header" >
              <Hidden smDown >
                <Grid container justify="center"
                  alignItems="center" >
                  <Grid item md={4} style={{}}>
                    <img className="pointer-cursor" onClick={() => history.push("/")} style={{ height: '3rem', paddingLeft: '4vw' }} src={iconHeader} alt="" />
                  </Grid>
                  <Grid item md={8}>
                    <Grid container spacing={2} justify="flex-end"
                      alignItems="center">
                      <label onClick={() => history.push("/about")} className="font-montserrat-medium font-size-px-sm letter-spacing-s color-white Nav-Bar nav-bar-margin">About Us</label>
                      <label onMouseOver={handleClick} className="font-montserrat-medium font-size-px-sm letter-spacing-s color-white Nav-Bar nav-bar-margin" style={{
                        textDecoration: anchorEl ? "underline" : 'none',
                        textDecorationColor: anchorEl ? '#F1A039' : 'none',
                        textDecorationThickness: anchorEl ? 3 : 0,
                        textUnderlinePosition: anchorEl ? "under" : 'none',
                      }} >Products</label>
                      <HashLink smooth to={'/#our-clients'}>
                        <label className="font-montserrat-medium font-size-px-sm letter-spacing-s color-white Nav-Bar nav-bar-margin"
                        >Our Clients</label>
                      </HashLink>
                      <label onClick={() => history.push("/contact")} className="font-montserrat-medium font-size-px-sm letter-spacing-s color-white Nav-Bar nav-bar-margin">Contact Us</label>
                      <SimplePopover title={<FontAwesomeIcon color="white" size='1x' icon={["fas", "search"]} />} anchorEl={searchAnchorEl} setAnchorEl={setSearchAnchorEl} />
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid container justify="center"
                  alignItems="center" >
                  <img onClick={() => history.push("/")} style={{ height: '50%' }} src={iconHeader} alt="" />
                  <div style={{ position: 'absolute', right: '0px' }}>
                    {open ? <MdClose /> : <MdMeun />}
                  </div>
                </Grid>
              </Hidden>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {open ?
        <div style={{ position: 'fixed', inset: '0px', height: '95vh', width: '100%', zIndex: 1000 }}>
          <div style={{ height: '100%', position: 'relative', top: '6vh', background: '#12043F' }}>
            <div style={{ padding: '5vh 5vw' }}>
              <Autocomplete
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    history.push("/search?words=" + event.target.value.replace(' ', '_'))
                    window.location.reload()
                  }
                }}
                fullWidth
                options={productName}
                getOptionLabel={(option) => option}
                onChange={(e) => {
                  const productIndex = productName.findIndex((title) => { return title.toLowerCase() === e.target.innerHTML.toLowerCase() })
                  if (productIndex >= 0) {
                    handlePush('product/' + productRoute[productIndex])
                  }
                }
                }
                classes={{ clearIndicatorDirty: classes.clearIndicator, popupIndicator: classes.popupIndicator, paper: classes.searchPaper, listbox: classes.searchListBox }}
                renderInput={(params) => (
                  <Grid container >
                    <Grid item xs={11}>
                      <CssTextFieldNew
                        {...params}
                        id="web-nav-search"
                        label=""
                        InputProps={{
                          style: {
                            color: 'white',
                            fontFamily: 'Nunito',
                            fontWeight: 400

                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ paddingRight: '1px' }}>
                      <FontAwesomeIcon color="white" size='sm' icon={["fas", "search"]} />
                    </Grid>
                  </Grid>

                )}
              />


              <List component="nav" style={{ width: '100%' }}>
                <ListItem button onClick={() => handlePush('about')}>
                  <ListItemText primary={<label className="font-montserrat-medium font-size-px-sm letter-spacing-s color-white">About Us</label>} />
                </ListItem>
                <ListItem button onClick={() => setListOpen(!listOpen)}>

                  <ListItemText primary={<label className="font-montserrat-medium font-size-px-sm letter-spacing-s color-white"  >Products</label>} />

                  {listOpen ? <FontAwesomeIcon color="white" icon={["fas", "chevron-up"]} /> : <FontAwesomeIcon color="white" icon={["fas", "chevron-down"]} />}

                </ListItem>
                <Collapse in={listOpen} timeout="auto" unmountOnExit>
                  <Paper style={{ maxHeight: '40vh', overflow: 'auto', backgroundColor: '#12043F' }}>
                    <List component="div" style={{ marginLeft: '2vw' }}>
                      <ListItem button onClick={() => handlePush('product/Inspection')}>
                        <ListItemText primary={<label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm">DWSS - Cerebro Inspection</label>} />
                      </ListItem>
                      <ListItem button onClick={() => handlePush('product/Diary')}>
                        <ListItemText primary={<label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm">DWSS - Cerebro Diary</label>} />
                      </ListItem>
                      <ListItem button onClick={() => handlePush('product/EDMS')}>
                        <ListItemText primary={<label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm" >EDMS - Cerebro Project</label>} />
                      </ListItem>
                      <ListItem button onClick={() => handlePush('product/BIM')}>
                        <ListItemText primary={<label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm" >Building Information Modelling</label>} />
                      </ListItem>
                      <ListItem button onClick={() => handlePush('product/KMS')}>
                        <ListItemText primary={<label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm">Cerebro Knowledge Management System</label>} />
                      </ListItem>
                      <ListItem button onClick={() => handlePush('product/Analyse')}>
                        <ListItemText primary={<label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm" >Cerebro Analyse</label>} />
                      </ListItem>
                      <ListItem button onClick={() => handlePush('product/Procurement')}>
                        <ListItemText primary={<label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm">Cerebro Procurement</label>} />
                      </ListItem>
                      <ListItem button onClick={() => handlePush('product/PhotoReg')}>
                        <ListItemText primary={<label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm">Cerebro Project - Photo Registration</label>} />
                      </ListItem>
                      <ListItem button onClick={() => handlePush('product/Signature')}>
                        <ListItemText primary={<label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm">Cerebro Signature App</label>} />
                      </ListItem>
                    </List>
                  </Paper>
                </Collapse>
                <ListItem onClick={() => setOpen(false)}>
                  <HashLink smooth to={'/#our-clients'}>
                    <ListItemText primary={<label className="font-montserrat-medium font-size-px-sm letter-spacing-s color-white">Our Clients</label>} />
                  </HashLink>
                </ListItem>
                <ListItem button onClick={() => handlePush('contact')}>
                  <ListItemText primary={<label className="font-montserrat-medium font-size-px-sm letter-spacing-s color-white">Contact Us</label>} />
                </ListItem>
                <ListItem button onClick={() => hadnleRequest()} >
                  <Button className="btn-transition-none btn-gradient shadow-none" onClick={() => setOpen(true)} variant="contained" size='medium' color="primary" style={{ width: '250px', borderRadius: 5, backgroundColor: '#6561B2', marginLeft: '1%' }}>
                    <label className="font-montserrat-bold letter-spacing-s font-size-rem-xs button-padding-h" style={{ textTransform: 'initial' }}>Request a Demo</label>
                  </Button>
                </ListItem>

              </List>
            </div>
          </div>
        </div>
        : null}

      <ModalScreen open={requestOpen} setOpen={setRequestOpen} />

      <StyledMenu
        id="simple-menu"
        className={classes.menu}
        anchorEl={anchorEl}
        style={{ top: '14px' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          onMouseLeave: handleClose,
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
        >
          <Grid
            item
            xs={6}
          >
            <StyledMenuItem disableRipple disableTouchRipple onClick={() => handleSelected('Inspection')}>
              <label className="w-f color-white font-nunito-semibold letter-spacing-s font-size-px-sm Nav-Bar" >DWSS - Cerebro Inspection</label>
            </StyledMenuItem>
            <StyledMenuItem disableRipple disableTouchRipple onClick={() => handleSelected('Diary')}>
              <label className="color-white font-nunito-semibold letter-spacing-s  font-size-px-sm Nav-Bar" >DWSS - Cerebro Diary</label>
            </StyledMenuItem>
            <StyledMenuItem disableRipple disableTouchRipple onClick={() => handleSelected('EDMS')}>
              <label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm Nav-Bar" >EDMS - Cerebro Project</label>
            </StyledMenuItem>
            <StyledMenuItem disableRipple disableTouchRipple onClick={() => handleSelected('BIM')}>
              <label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm Nav-Bar" >Building Information Modelling</label>
            </StyledMenuItem>
            <StyledMenuItem disableRipple disableTouchRipple onClick={() => handleSelected('KMS')}>
              <label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm Nav-Bar" >Cerebro Knowledge Management System</label>
            </StyledMenuItem>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <StyledMenuItem disableRipple disableTouchRipple onClick={() => handleSelected('Analyse')}>
              <label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm Nav-Bar" >Cerebro Analyse</label>
            </StyledMenuItem>
            <StyledMenuItem disableRipple disableTouchRipple onClick={() => handleSelected('Procurement')}>
              <label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm Nav-Bar" >Cerebro Procurement</label>
            </StyledMenuItem>
            <StyledMenuItem disableRipple disableTouchRipple onClick={() => handleSelected('PhotoReg')}>
              <label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm Nav-Bar" >Cerebro Project - Photo Registration</label>
            </StyledMenuItem>
            <StyledMenuItem disableRipple disableTouchRipple onClick={() => handleSelected('Signature')}>
              <label className="color-white font-nunito-semibold letter-spacing-s font-size-px-sm Nav-Bar" >Cerebro Signature App</label>
            </StyledMenuItem>

          </Grid>
        </Grid>

      </StyledMenu>
    </React.Fragment>
  )
}


