export const searchProductKnowledgement = {
  title:'Knowledge Management System',
  short:'KMS',
  searchText:'Knowledge Management System ' +
      'Request a demo ' +
      'A centralised knowledge sharing platform for all construction project know-hows. Authorized construction professionals can update project information where management teams can review and track all construction records and progress, even from long finished projects at ease. ' +
      'Function Management MIC PIC Admin ' +
      'View Information ' +
      'Upload Information ' +
      'Edit Information ' +
      'Approve Information ' +
      'Assign Task ' +
      'View Statistics ' +
      'Edit User'+
      'Project Managment'+
      'Knowledge Management'+
      'Web Application'+
      'Knowledge Sharing',
  description:'A centralised knowledge sharing platform for all construction project know-hows. Authorized construction professionals can update project information where management teams can review and track all construction records and progress, even from long finished projects at ease.',
  path:'/product/KMS'
}