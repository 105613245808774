import { makeStyles } from '@material-ui/core/styles';
import './App.css';
import React from 'react';
import { TextField} from '@material-ui/core';




export function NewTextField(props){
    const useStyles = makeStyles((theme) => ({
        root: {
            "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                borderColor: 'black'
              },
          '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: props.width?props.width:'100%',
            '&:hover':{
                borderColor:'black'
            }
          },
         
        },
        disabled: {},
        focused: {},
        error: {},
        cssLabel: {
            color : `${props.labelcolor}!important`
        },
        cssOutlinedInput: {
            '&$cssFocused $notchedOutline': {
                borderColor: props.inputcolor?props.inputcolor:`${theme.palette.primary.main} !important`,
                color:props.inputcolor?props.color:'white'
            }
        },
        
        cssFocused: {
            color:props.focused,
        },
        notchedOutline: {
            borderWidth: '1px',
            borderColor: `${props.border}!important`,
            color:props.inputcolor
          },
       
      }));
    const classes = useStyles();
   
    return(
        <TextField 
            // color='primary' 
            fullWidth
            key={props.id}
            id={props.id}
            {...props}
            label={<label className="font-nunito-regular letter-spacing-s">{props.label}</label>}
            variant={props.variant?props.variant:"outlined"}  
            InputLabelProps={{
                classes: {
                root: classes.cssLabel,
                focused: classes.cssLabel,
                },
                
            }}
            InputProps={{
                endAdornment:props.endAdornment?props.endAdornment:null,
                classes: {
                    root: classes.root,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                    input: classes.cssLabel
                },
                style:{
                    height:props.height,
                    fontFamily:'Nunito',
                    fontWeight:400,
                }
                }}
        />
    )
}