import './App.css';
import React, { useEffect, useRef,useState } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import anime from 'animejs/lib/anime.es.js';
import { FaCheck } from "react-icons/fa";
import emailjs from 'emailjs-com';
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export function SendBTN(props){
  const animationRef = useRef(null);
  const [ progress, setProgress ] = useState(0)
  const [ opacity, setOpacity ] = useState(0)
  function sendEmail(e) {
    // console.log(e)   
    let formData = new FormData()
    formData.append('name', 'ABC');
    emailjs.send('service_f217afs', 'template_k4mi1df', e, 'user_7f0xR5nFuR2UPNuJ4eABH')
      .then((result) => {
          console.log('hi',result.text);
          setTimeout(()=>props.handleClose(),1000)
          
      }, (error) => {
          console.log('FAILED',error.text);
      });
  }


    
    useEffect(() => {
      var basicTimeline = anime.timeline({
        autoplay: false
      });
        animationRef.current =  basicTimeline
        .add({
          targets: ".text",
          duration: 1,
          opacity: 0
        }).add({
            targets: ".send-btn",
            duration: 400,
            width: 5,
            // backgroundColor: '#FFFFFF',
            border: 1,
            borderRadius:50,
            opacity:0,
            complete: function(anim) {
              setOpacity(1)
              setProgress(100)
              setTimeout(()=>
              setOpacity(0),1100)
            }
          })
          .add({
            targets: ".send-btn",
            duration: 300,
            width: '200px',
            delay:1100,
            // backgroundColor: '#6561B2',
            borderRadius:50,
            opacity:1,
            
          }).add({
            targets: ".tick",
            opacity:1,
            
          })
          
      }, []);
      const handleSubmit = () => {
        animationRef.current.restart()
        if(props.product){
          const productLst = Object.keys(props.product).filter(item=>props.product[item]===true)
          sendEmail({...props.userData,product:productLst.join(', ')})
        }else{
          sendEmail(props.userData)
        }
      

      }
    return(
        <div>
            <Grid container  alignItems="center">
                <Grid item  >
                  <FaCheck className="tick" size={20} color='white' style={{position:'absolute',zIndex:10,top:props.mode?'10.5px':'18px',left:'90px',opacity:0}}/>
                    <Button onClick={()=>handleSubmit()} variant="contained" className="send-btn" color="primary" style={{width:'200px',height:'40px',borderRadius:25,backgroundColor:'#6561B2'}}>
                        <label  onClick={()=>handleSubmit()} style={{}}  className="text font-montserrat-semibold color-white font-size-des">Send</label>
                    </Button>

                </Grid>
                <Grid  item >
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={progress}
                  duration={0.3}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    return (
                        <CircularProgressbar
                          value={value}
                          styles={{
                            root: {
                              width:'40px',
                              opacity:opacity,
                              position:'relative',
                              top:'-3rem'
                              

                            },

                          }}
                        />
                    );
                  }}
                </AnimatedProgressProvider>
                  {/* <CircularProgress className="progress" style={{opacity:0,position:'relative',top:props.mode?'-50px':'0px',left:props.mode?'0px':'-110px'}} /> */}
              </Grid>
            </Grid>
          
            
        </div>
        
    )
}