import './App.css';
import React,{ useState,useEffect} from 'react';
import { Container,Grid, Hidden} from '@material-ui/core';
import { Footer } from './footer'
import logo from './assets/image/logo_c.png'
import background from './assets/image/about_back.png'
import Marquee from "react-fast-marquee";
import Image1 from './assets/image/appengine_a.png'
import Image2 from './assets/image/cloudsql_a.png'
import Image3 from './assets/image/aws_a.png'
import Image4 from './assets/image/pwa.png'
import Image5 from './assets/image/tesserect_a.png'
import Image6 from './assets/image/gc_a.png'
import Image7 from './assets/image/tensorflow_a.png'
import { NavBar } from './navbar'
import { useWindowSize } from './windowSize'
import anime from 'animejs/lib/anime.es.js';
import {
    isMobile,
  } from "react-device-detect";

export function AboutUs(){
    const imgWidth = 1920
    const imgHeight = 700
    const [animation, setAnimation] = useState(false)
    const animationRef = React.useRef(null);
   
    const [width] = useWindowSize();
    useEffect(()=>{
        var basicTimeline = anime.timeline({
            autoplay: true
          });
        if(animation){
          animationRef.current =  basicTimeline
          .add({
            targets: [".about",".company-logo","#description",".logo",".technologies"],
            duration:1,
            translateY: 100,
            easing:'linear',
            opacity:0   
            
          })
          .add({
              targets: ".about",
              duration:300,
              translateY: 0,
              easing:'linear',
              opacity:1
              
            })
            .add({
                targets: ".company-logo",
                duration:300,
                translateY: 0,
                easing:'linear',
                opacity:1
                
              })
              .add({
                targets: "#description",
                duration:300,
                translateY: 0,
                easing:'linear',
                opacity:1
                
              })
              .add({
                targets: ".technologies",
                duration:300,
                translateY: 0,
                easing:'linear',
                opacity:1
                
              })
              .add({
                targets: ".logo",
                duration:300,
                translateY: 0,
                easing:'linear',
                opacity:1
                
              })
          }
    },[animation])

    useEffect(()=>{
        function animationStart() {
            setAnimation(true)
            
          }
        window.addEventListener('load', animationStart);
        animationStart()
        return () => window.removeEventListener('load', animationStart);
      })

    return(
        <div style={{backgroundColor:"white"}}>
            
            {/* <NavBar /> */}
            <div style={{height:'fit-content'}}>
                    <Hidden xsDown>
                        <div className="About-Container" >
                            <div className="w-100" style={{position:'absolute'}}>
                            <Container maxWidth="lg">
                                <div className="about" style={{position:'relative',top:width/imgWidth*imgHeight/2,left:'4vw'}}>
                                    <label className="font-montserrat-bold color-white letter-spacing-m font-size-rem-lg banner-text-shadow">About Us </label>
                                    <label style={{paddingLeft:'1vw'}} className="font-montserrat-bold color-orange font-size-rem-lg banner-text-shadow">.</label>
                                </div>
                            </Container>
                            </div>
                            <img  src={background}  className="w-100" alt=""/>
                        </div>
                    </Hidden>
                    <Hidden smUp>
                        <Grid style={{height:'40vh',backgroundColor:'#12043F'}} container  alignItems='flex-end' >
                            <div style={{paddingBottom:'5vh',paddingLeft:'calc(24px + 5vw)'}}>
                                <label style={{fontSize:'10vmin'}} className="font-montserrat-bold color-white letter-spacing-m font-size-rem-md">About Us </label>
                                <label style={{fontSize:'10vmin',paddingLeft:'1vw'}} className="font-montserrat-bold color-orange font-size-rem-md">.</label>
                            </div>
                        </Grid>
                        
                    </Hidden>
                    
                </div>
            <Container  maxWidth="lg" style={{overflow:'hidden',backgroundColor:"white"}}> 
                <Grid container
                    style={{padding:"0 4vw"}}
                    alignItems="center" justify="center">
                    <Grid 
                        style={{}}
                        item   xs={12} sm={5} md={4}>
                        <img  src={logo} className="company-logo margin-nodot-top" alt=""/>
                    </Grid>
                    <Grid 
                        item xs={12} sm={7} md={8}>
                        <label id="description" style={{lineHeight:'200%'}} className="w-100 font-nunito-regular color-black-medium letter-spacing-s margin-nodot-top">Founded in 2019 and headquartered in Hong Kong, Cerebro has been consistently expanding as an innovative solution provider. With a sound background of construction and software development, our 100% Hong Kong based in-house teams are fully equipped with the necessary skill sets and experience to assist businesses in fostering digital transformation, streamline business workflows and increase project quality.</label>
                    </Grid>
                   

                </Grid>
                <Grid container
                    direction="row" className="technologies margin-dot-top" style={{padding:"0 4vw"}} alignItems="flex-end">
                        <label className="font-montserrat-bold color-purple font-size-rem-lg">.</label>
                </Grid>
                <Grid container
                    direction="row" className="technologies" style={{padding:"0 4vw"}} alignItems="flex-end">
                        <Grid item>
                            <label className="font-montserrat-bold color-black font-size-header margin-header-content">Technologies we use</label>
                        </Grid>
                </Grid>
            
                <Grid container
                    className="logo margin-end-content"
                    style={{}}
                    direction="row" >
                        <Hidden smDown>
                        <Grid item
                        style={{overflow:'hidden',width:'100%'}}
                             xs={12}  >
                             <Marquee pauseOnHover={true} speed={100}>
                                        <img style={{height:'3rem',marginLeft:'10px'}} className="iconDes Protfolio-Icon Ticker-image" src={Image1} alt="" />
                                        <img style={{height:'3rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image2} alt="" />
                                        <img style={{height:'3rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image3} alt="" />
                                        <img style={{height:'3rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image4} alt="" />
                                        <img style={{height:'3rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image5} alt="" />
                                        <img style={{height:'3rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image6} alt="" />
                                        <img style={{height:'3rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image7} alt="" />
                     
                            </Marquee>
                        </Grid>
                        </Hidden>
                        <Hidden only={['xs','lg','md','xl']}>
                        <Grid item
                        style={{overflow:'hidden',width:'100%'}}
                             xs={12}  >
                            <Marquee pauseOnHover={true} speed={100}>
                                        <img style={{height:'2.5rem',marginLeft:'10px'}} className="iconDes Protfolio-Icon Ticker-image" src={Image1} alt="" />
                                        <img style={{height:'2.5rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image2} alt="" />
                                        <img style={{height:'2.5rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image3} alt="" />
                                        <img style={{height:'2.5rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image4} alt="" />
                                        <img style={{height:'2.5rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image5} alt="" />
                                        <img style={{height:'2.5rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image6} alt="" />
                                        <img style={{height:'2.5rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image7} alt="" />
                       
                                
                            </Marquee>
                        </Grid>
                        </Hidden>
                        <Hidden smUp>
                        <Grid item
                        style={{overflow:'hidden',width:'100%'}}
                             xs={12}  >
                           <Marquee  speed={100}>
                                        <img style={{height:'2rem',marginLeft:'10px'}} className="iconDes Protfolio-Icon Ticker-image" src={Image1} alt="" />
                                        <img style={{height:'2em',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image2} alt="" />
                                        <img style={{height:'2rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image3} alt="" />
                                        <img style={{height:'2rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image4} alt="" />
                                        <img style={{height:'2em',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image5} alt="" />
                                        <img style={{height:'2rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image6} alt="" />
                                        <img style={{height:'2rem',marginLeft:'10px'}}  className="iconDes Protfolio-Icon Ticker-image" src={Image7} alt="" />
                            </Marquee>
                        </Grid>
                        </Hidden>
                
                </Grid> 
            </Container>
            <Footer />
        </div>
    )
}