export const searchProductInspection = {
  title:'Digital Works Supervision System (DWSS) Cerebro Inspection',
  short:'Inspection',
  searchText:'Digital Works Supervision System (DWSS) Cerebro Inspection Request a demo ' +
      'A barrier-free inspection platform for all types of inspections and workflows. All inspections are conducted via smartphones, tablets or web browsers with selections of straightforward drop down options and check boxes with photo capture and upload function, streamlining inspection activities. ' +
      'On Cerebro Inspection, all inspection records will be submitted and completed digitally, without single use of paper. All records will be stored on clouds - so no more losing of inspection records! ' +
      'With a camera equipped phone, site photos can be captured and attached to the digital inspection form directly. For easy reference, you can even add sketches or annotation to the image. ' +
      'Inspection records will be consolidated and analysed as key performance indicators (KPI) for project supervisors to tackle on underperformed inspection categories. Reports can be exported and downloaded as PDF and Excel format. ' +
      'All inspections records are time stamped and electronically signed with names and positions to ensure all responsibility and accountability can be tracked.'+
      'Inspection'+
      'Site Managment'+
      'DWSS'+
      'Safety'+
      'Survey'+
      'Quality'+
      'Environmental'+
      'Mobile Application'+
      'Web Application',
  description:'A barrier-free inspection platform for all types of inspections and workflows. All inspections are conducted via smartphones, tablets or web browsers with selections of straightforward drop down options and check boxes with photo capture and upload function, streamlining inspection activities.',
  path:'/product/Inspection'
}