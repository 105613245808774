import './App.css';
import React from 'react';
import { Grid,Hidden } from '@material-ui/core';
import Partner1 from './assets/image/partner1.png'
import Partner2 from './assets/image/partner2.png'
import Partner3 from './assets/image/partner3.png'
import Partner4 from './assets/image/partner4.png'
import Partner5 from './assets/image/partner5.png'
import Partner6 from './assets/image/partner6.png'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

export function FifthSection(props){
    return(
        <div ref={props.reference} id="our-clients" style={{padding:'0 4vw'}}>
            <div className="Content-Container">
                <Grid container
                    item direction="column" justify="center">
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                        <Grid 
                            item  xs={12} >
                            <label className="font-montserrat-bold color-blue font-size-rem-xl margin-dot-top">.</label>
                        </Grid>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                        <Grid 
                            item  xs={12} >
                            <label className="font-montserrat-bold color-black letter-spacing-m font-size-header margin-header-content" style={{}}>Our Clients</label>
                        </Grid>
                    </ScrollAnimation>
                </Grid>
                <div style={{marginBottom:'calc(80px - 2vh)'}}>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                        <Hidden xsDown>
                            <img style={{height:"100px"}} className="Protfolio-Icon margin-header-content" src={Partner1} alt=""/>
                            <img  style={{height:"100px"}} className="Protfolio-Icon margin-header-content" src={Partner2} alt=""/>
                            <img style={{height:"100px"}}  className="Protfolio-Icon margin-header-content" src={Partner3} alt=""/>
                            <img style={{height:"100px"}}  className="Protfolio-Icon margin-header-content" src={Partner4} alt=""/>
                            <img style={{height:"100px"}}  className="Protfolio-Icon margin-header-content" src={Partner5} alt=""/>
                            {/* <img style={{height:"100px"}}  className="Protfolio-Icon margin-header-content" src={Partner6} alt=""/> */}
                        </Hidden>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__fadeInUp'  animateOnce={true} animatePreScroll={false}>
                        <Hidden smUp>
                            <img style={{height:"70px"}} className="Protfolio-Icon margin-header-content" src={Partner1} alt=""/>
                            <img  style={{height:"70px"}} className="Protfolio-Icon margin-header-content" src={Partner2} alt=""/>
                            <img style={{height:"70px"}}  className="Protfolio-Icon margin-header-content" src={Partner3} alt=""/>
                            <img style={{height:"70px"}}  className="Protfolio-Icon margin-header-content" src={Partner4} alt=""/>
                            <img style={{height:"70px"}}  className="Protfolio-Icon margin-header-content" src={Partner5} alt=""/>
                            {/* <img style={{height:"70px"}}  className="Protfolio-Icon margin-header-content" src={Partner6} alt=""/> */}
                        </Hidden>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
       
    )
}